import QuizThirdStepTitleOne from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleOne'
import QuizThirdStepFormOne from '../../molecules/QuizThirdStepForm/QuizThirdStepFormOne'
import QuizThirdStepTitleTwo from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleTwo'
import QuizThirdStepFormTwo from '../../molecules/QuizThirdStepForm/QuizThirdStepFormTwo'
import QuizThirdStepTitleThree from '../../molecules/QuizThirdStepTitle/QuizThirdStepTitleThree'
import QuizThirdStepFormThree from '../../molecules/QuizThirdStepForm/QuizThirdStepFormThree'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import React, { useEffect } from 'react'
import Steps from '../../molecules/Steps/Steps'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'
import { quiz } from '../../../app/services/Api'
import { setSection } from '../../../app/quiz/quizSlice'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizThirdStep ({ className, currentPage, handleShowPage }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const section = useAppSelector(state => state.quizReducer.section)
  const preSignedupUserId = useAppSelector(state => state.quizReducer.userPreSignupId)
  const genie = useAppSelector(state => state.quizReducer.Genie)
  const Q1_1 = useAppSelector(state => state.quizReducer.Q1_1)
  const Q2_1 = useAppSelector(state => state.quizReducer.Q2_1)
  const Q2_2 = useAppSelector(state => state.quizReducer.Q2_2)
  const Q2_3 = useAppSelector(state => state.quizReducer.Q2_3)
  const Q2_4 = useAppSelector(state => state.quizReducer.Q2_4)
  const Q2_5 = useAppSelector(state => state.quizReducer.Q2_5)
  const Q2_6 = useAppSelector(state => state.quizReducer.Q2_6)
  const Q2_7 = useAppSelector(state => state.quizReducer.Q2_7)
  const Q2_8 = useAppSelector(state => state.quizReducer.Q2_8)
  const Q2_9 = useAppSelector(state => state.quizReducer.Q2_9)
  const Q2_10 = useAppSelector(state => state.quizReducer.Q2_10)
  const Q2_11 = useAppSelector(state => state.quizReducer.Q2_11)
  const Q2_12 = useAppSelector(state => state.quizReducer.Q2_12)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (section === '' && currentUser?.quiz_step?.section === undefined) {
      let preSignupId
      if (preSignedupUserId === 0) {
        preSignupId = currentUser?.pre_signup_id ?? 0
      } else {
        preSignupId = preSignedupUserId
      }

      void quiz.getQuizSection({
        Q1_1,
        Q2_1,
        Q2_2,
        Q2_3,
        Q2_4,
        Q2_5,
        Q2_6,
        Q2_7,
        Q2_8,
        Q2_9,
        Q2_10,
        Q2_11,
        Q2_12,
        Genie: genie
      }, preSignupId).then((response) => {
        dispatch(setSection(response.score.Section))
      }).catch((error) => {
        console.log(error)
      })
    }

    if (currentUser?.quiz_step?.step_name === 'Step Three' && currentUser?.quiz_step.section !== undefined) {
      dispatch(setSection(currentUser?.quiz_step.section))
    }
  }, [currentUser])

  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <ProgressBar value={6} className='w-full md:w-1/2 mx-auto my-2'/>
      <QuizThirdStepTitleOne className='flex flex-col relative visible_1 justify-center items-center gap-5' />

      <QuizThirdStepFormOne className='flex flex-row relative visible_1 mt-5 justify-center items-center w-full'/>
      <QuizThirdStepTitleTwo className='flex flex-col relative visible_1 justify-center items-center mt-10 mb-5'/>
      <QuizThirdStepFormTwo className='last_step_1 flex relative visible_1 justify-center'/>
      <QuizThirdStepTitleThree className='flex flex-col relative visible_1 justify-center items-center mt-10 mb-5'/>
      <QuizThirdStepFormThree className='last_step_2 flex flex-col relative visible_1 justify-center items-center mb-5' handleShowPage={handleShowPage}/>
    </div>
  )
}
