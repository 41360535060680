import React, { useEffect } from 'react'
import Steps from '../../../molecules/Steps/Steps'
import { ProgressBar } from '../../../atoms/ProgressBar/ProgressBar'
import Header from '../../../atoms/Heading/Heading'
import FormControl from '@mui/material/FormControl'
import { Chip, Divider, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import TextField from '@mui/material/TextField'
import { users } from '../../../../app/services/Api'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { EmployeeStatus, PAGE, setCurrentUser } from '../../../../app/quiz/quizSlice'
import { PopoverTooltip } from '../../../molecules/PopOver/PopoverTooltip'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

interface Option {
  id: number
  label: string
  tooltip?: string
}

export function EmployeeStatusPage ({ className, currentPage, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.quizReducer.currentUser)
  const [companyName, setCompanyName] = React.useState('')
  const [selectedOption, setSelectedOption] = React.useState<number>(0)
  const [isFieldVisible, setIsFieldVisible] = React.useState(false)
  const [keywords, setKeywords] = React.useState<string[]>([])
  const isMobile = window.innerWidth < 768

  const options: Option[] = [
    {
      id: 1,
      label: 'I\'m currently employed'
    },
    {
      id: 2,
      label: 'I\'m employed but open to work',
      tooltip: 'You’re currently employed but actively looking for a new workplace or employer.'
    },
    {
      id: 3,
      label: 'I\'m currently unemployed'
    }
  ]

  const getSelectedStatus = (option: number) => {
    switch (option) {
      case 1:
        return EmployeeStatus.EMPLOYED
      case 2:
        return EmployeeStatus.EMPLOYED_LOOKING
      default:
        return EmployeeStatus.UNEMPLOYED
    }
  }

  const getNextPage = () => {
    if (selectedOption === 3) {
      return PAGE.EMPLOYEE_KEYWORDS
    }

    return PAGE.EMPLOYEE_EMPLOYER
  }

  const getNextStep = () => {
    if (selectedOption === 3) {
      return 'Employee Keywords'
    }

    return 'Employee Employer'
  }

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (user === null) {
      return
    }

    const option = Number(e.target.value)
    setSelectedOption(option)

    void users.updateRegisteredUser(user.id, {
      employee_status: getSelectedStatus(option)
    }).then(() => {
      dispatch(setCurrentUser({
        ...user,
        employee_status: getSelectedStatus(option)
      }))
    })
  }

  const handleNextPage = () => {
    if (user === null) {
      return
    }

    void users.updateRegisteredUser(user.id, {
      employee_status: getSelectedStatus(selectedOption),
      employee_future_employer: keywords.join(', '),
      step_name: getNextStep()
    }).then(() => {
      dispatch(setCurrentUser({
        ...user,
        employee_status: getSelectedStatus(selectedOption),
        employee_future_employer: keywords.join(', ')
      }))

      handleShowPage(getNextPage())
    })
  }

  useEffect(() => {
    setIsFieldVisible([2, 3].includes(selectedOption))
  }, [selectedOption])

  useEffect(() => {
    if (user === null) {
      return
    }

    switch (user.employee_status) {
      case EmployeeStatus.EMPLOYED:
        setSelectedOption(1)
        break
      case EmployeeStatus.EMPLOYED_LOOKING:
        setSelectedOption(2)
        break
      case EmployeeStatus.UNEMPLOYED:
        setSelectedOption(3)
        break
      default:
        break
    }

    if (user.employee_future_employer !== null && user.employee_future_employer !== '') {
      setKeywords(user.employee_future_employer.split(', '))
    }
  }, [])

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  const addKeyword = (): void => {
    const trimmedValue = companyName.trim()
    if (trimmedValue.length > 0) {
      const words = trimmedValue.split(',')
      const newKeywords = words.map(word => word.trim())
      setKeywords([...keywords, ...newKeywords])
      setCompanyName('')
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      addKeyword()
    }
  }

  useEffect(() => {
    if (user === null) {
      return
    }

    void users.updateRegisteredUser(user.id, {
      employee_future_employer: keywords.join(', ')
    }).then(() => {
      dispatch(setCurrentUser({
        ...user,
        employee_future_employer: keywords.join(', ')
      }))
    })
  }, [keywords])

  return (
        <div className={className}>
            <Steps currentPage={currentPage}/>
            <ProgressBar value={4} isRegistration={true} className='w-full md:w-1/2 mx-auto mt-0'/>

            <div className='visible_1'>
                <Header className='main_header_home text-lg md:text-2xl mt-3 mb-4 md:mt-4 text-center'
                        text="What's Your Employment Status?"/>

                <FormControl>
                    <RadioGroup value={selectedOption} onChange={handleSelectionChange} name="radio-buttons-group">
                        {options.map(option => (
                            <FormControlLabel
                                key={option.id}
                                value={option.id}
                                control={<Radio/>}
                                sx={{
                                  '& .MuiFormControlLabel-label': {
                                    fontSize: isMobile ? '14px' : 'initial'
                                  }
                                }}
                                label={
                                    <>
                                        {option.label}
                                        {option?.tooltip !== undefined &&
                                            <PopoverTooltip tooltip={option.tooltip} className='inline-block ml-2 mb-1' />
                                        }
                                    </>
                                }/>

                        ))}
                    </RadioGroup>
                </FormControl>
            </div>

            <div className={`w-[90%] md:w-[50%] mt-3 ${isFieldVisible ? 'visible_1' : 'hidden'}`}>
                <Divider/>

                <Header className='text-lg md:text-xl font-medium mt-5 mb-2 text-center'
                        text="Which companies would you like to be noticed by?"/>
                <p className='text-sm md:text-base text-center mb-6'>
                    Describe your desired employer. <br className='md:hidden'/> Use company references or
                    write which general industry you’d like to work at.
                </p>

                <TextField
                    label='Write your own topics'
                    placeholder='This is optional'
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="outlined"
                    value={companyName}
                    className='w-full'
                    onKeyDown={(e) => {
                      handleKeyDown(e)
                    }}

                    onChange={(e) => {
                      if (e.target.value === ',') {
                        return
                      }

                      setCompanyName(e.target.value)
                    }}
                    InputProps={{
                      style: {
                        borderRadius: '8px'
                      },
                      endAdornment: (
                            <button
                                type="button"
                                className={'rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300'}
                                onClick={addKeyword}
                            >
                                Add
                            </button>
                      )
                    }}
                />

                <div className="flex flex-wrap justify-center mt-4">
                    {keywords.map((keyword, index) => (
                        <div key={index} className="m-1">
                            <Chip label={keyword} onDelete={handleChipDelete(keyword)}/>
                        </div>
                    ))}
                </div>
            </div>

            <button onClick={handleNextPage}
                    className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16 !mt-4'>
                {keywords.length === 0 && isFieldVisible ? 'Skip' : 'Next'}
            </button>
        </div>
  )
}
