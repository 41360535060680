import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export enum RegenerationType {
  REGENERATE = 'Regenerate',
  REWRITE = 'Rewrite'
}

export interface ViralTopic {
  id: number
  title: string
  content: string
  citations: string
}

export interface ContentGeneratorState {
  selectedContentHistory: ContentHistory | null
  contentHistories: ContentHistory[]
  topic: Topic | null
  isHistoryHidden: boolean
  isFromBrandaSuggestion: boolean
  isHistoryItemClicked: boolean
  isGuideRunning: boolean
  isSingleTaskTopic: boolean
  isFirstTime: boolean
}

export interface MainCategory {
  id: number
  title: string
  description: string
}

export interface Topic {
  id: number
  name: string
  short_name?: string
  description?: string
  is_trial: boolean
  include_in_suggestion: boolean
  main_category: MainCategory
}

export interface Task {
  id: number
  task_title: string
  task_body: string
  personalization: string
  isActive: boolean
  platform: string
  subject_for_ai: boolean
  is_rewrite: boolean
  is_free_writing: boolean
}

export interface ContentHistory {
  id: number
  task: Task
  viral_topic: ViralTopic | null
  employee_task: Task | null
  title: string
  personalization: string | null
  language: string
  response: string | null
  created_at: number
  rating: number | null
  citations: string[] | null
}

const initialState: ContentGeneratorState = {
  selectedContentHistory: null,
  contentHistories: [],
  isFromBrandaSuggestion: false,
  topic: null,
  isHistoryHidden: false,
  isHistoryItemClicked: false,
  isGuideRunning: false,
  isSingleTaskTopic: false,
  isFirstTime: false
}

export const contentGeneratorSlice = createSlice({
  name: 'contentGeneratorSlice',
  initialState,
  reducers: {
    setSelectedContentHistory: (state, action: PayloadAction<ContentHistory | null>) => {
      state.selectedContentHistory = action.payload
    },
    setContentHistory: (state, action: PayloadAction<ContentHistory[]>) => {
      state.contentHistories = action.payload.map((item) => {
        if (item.task === null && item.employee_task !== null) {
          item.task = item.employee_task
        }

        return item
      })
    },
    appendContentHistory: (state, action: PayloadAction<ContentHistory>) => {
      state.contentHistories.push(action.payload)
    },
    setIsFromBrandaSuggestion: (state, action: PayloadAction<boolean>) => {
      state.isFromBrandaSuggestion = action.payload
    },
    setSelectedTopic: (state, action: PayloadAction<Topic | null>) => {
      state.topic = action.payload
    },
    setHistoryItemClicked: (state, action: PayloadAction<boolean>) => {
      state.isHistoryItemClicked = action.payload
    },
    setIsGuideRunning: (state, action: PayloadAction<boolean>) => {
      state.isGuideRunning = action.payload
    },
    setIsSingleTaskTopic: (state, action: PayloadAction<boolean>) => {
      state.isSingleTaskTopic = action.payload
    },
    setIsHistoryHidden: (state, action: PayloadAction<boolean>) => {
      state.isHistoryHidden = action.payload
    },
    setIsFirstTimeViewer: (state, action: PayloadAction<boolean>) => {
      state.isFirstTime = action.payload
    }
  }
})

export const {
  setSelectedContentHistory,
  setContentHistory,
  setIsFromBrandaSuggestion,
  setSelectedTopic,
  appendContentHistory,
  setHistoryItemClicked,
  setIsGuideRunning,
  setIsSingleTaskTopic,
  setIsHistoryHidden,
  setIsFirstTimeViewer
} = contentGeneratorSlice.actions

export default contentGeneratorSlice.reducer
