/* eslint-disable @typescript-eslint/no-misused-promises */
import { TextField } from '@mui/material'
import Icon from '../../atoms/Icon/Icon'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { users } from 'app/services/Api'
import { useState } from 'react'
import { useAppSelector } from 'app/hooks'
import { toast } from 'react-toastify'

interface Props {
  className: string
}

export default function FooterIcons ({ className }: Props) {
  const location = useLocation().pathname
  const [trialKey, setTrialKey] = useState('')
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const navigate = useNavigate()

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await users.userFreeTrail(trialKey, currentUser?.email ?? '')
      .then(() => {
        navigate('/content-generator')
      }).catch((err) => {
        toast.error(err.response.data)
      })
  }

  return (
    <div className="flex items-center">
      {location === '/subscription' && (
        <div className='flex items-center mr-2'>
          <p className='mr-2 text-sm'>Trial Key</p>
          <TextField
            id="trial-key"
            name='trial-key'
            className='w-32'
            size="small"
            variant="outlined"
            placeholder="TRIAL-KEY"
            label=""
            onChange={(e) => { setTrialKey(e.target.value) }}
          />
          <button type="submit" className="button_bg w-12 ml-2 p-2 text-sm text-center font-light capitalize text-white rounded-lg" onClick={handleSubmit}>
            Go!
          </button>
        </div>
      )}
      <div className={`${className} w-full text-mobile-lg`}>
        <a href='https://www.facebook.com/myBranda.FB' target='_blank' rel='noreferrer'>
        <Icon icon={brands('facebook')} style={{ color: '#1d1c1f' }} />
        </a>
        <a href='https://www.instagram.com/mybranda.insta/?igshid=YmMyMTA2M2Y%3D' target='_blank' rel='noreferrer'>
          <Icon icon={brands('square-instagram')} style={{ color: '#1d1c1f' }} />
        </a>
        <a href='https://www.linkedin.com/company/81458505/' target='_blank' rel='noreferrer'>
          <Icon icon={brands('linkedin-in')} style={{ color: '#1d1c1f' }} />
        </a>
      </div>
    </div>
  )
}
