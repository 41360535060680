/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setBranda, setQuestion3 } from '../../../app/quiz/quizSlice'
import { quiz, users } from '../../../app/services/Api'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizThirdStepFormThree ({
  className,
  handleShowPage
}: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const Q1 = useAppSelector(state => state.quizReducer.Q1)
  const Q2 = useAppSelector(state => state.quizReducer.Q2)
  const Q3 = useAppSelector(state => state.quizReducer.Q3)
  const Gender = useAppSelector(state => state.quizReducer.Gender)
  const userPreSignupId = useAppSelector(state => state.quizReducer.userPreSignupId)
  const stateSection = useAppSelector(state => state.quizReducer.section)
  const [q3Value, setQ3Value] = useState(0)
  const [section, setSection] = useState<string | null>(null)
  const [content, setContent] = useState<string[]>([])

  const [isDisabled, setIsDisabled] = useState(true)

  useEffect(() => {
    if (stateSection !== '') {
      setSection(stateSection)
    } else if (currentUser?.quiz_step?.section !== undefined) {
      setSection(currentUser.quiz_step.section)
    }

    if (currentUser?.quiz_step?.Q3 !== undefined && Q3 === '') {
      dispatch(setQuestion3(currentUser?.quiz_step.Q3))
      setQ3Value(Number(currentUser?.quiz_step.Q3))
    }
  }, [currentUser])

  useEffect(() => {
    setIsDisabled(Q1 === '' || Q2 === '' || Q3 === '')
  }, [Q1, Q2, Q3])

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestion3(event.target.value))
    setQ3Value(Number(event.target.value))
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q3: event.target.value, step_name: 'Step Three' }, true)
  }

  const values: object = {
    Q1,
    Q2,
    Q3,
    Gender,
    Section: section
  }

  const handleGetQuizBranda = async () => {
    let preSignupId = 0
    if (userPreSignupId === 0) {
      preSignupId = currentUser?.pre_signup_id ?? 0
    } else {
      preSignupId = userPreSignupId
    }

    await quiz.getQuizBranda(values, preSignupId).then((response) => {
      dispatch(setBranda(response.score.Branda))
      handleShowPage('pageBrandaResult')
    }).catch((error) => {
      console.log(error)
    })
    await users.updateRegisteredUser(currentUser?.id ?? 0, { step_name: 'Page Result' }, true)
  }

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    await handleGetQuizBranda()
  }

  useEffect(() => {
    switch (section) {
      case 'E':
        setContent(['I fail to achieve an aspired goal', 'I can’t express myself in a unique way', 'I’m surrounded by conformists', 'People don’t listen to reason and logic'])
        break
      case 'I':
        setContent(['I can’t figure out how something works', 'Situations with others get too complicated', 'I don’t feel meaningful to others', 'I don’t do something exciting'])
        break
      case 'S':
        setContent(['I spend too much time in a low energy enviornment', 'I find myself in conflicts with others', 'I’m not seen or understood', 'I’m not able to keep my word'])
        break
      case 'C':
        setContent(['People don’t do their job', 'There\'s a lack of ethical standards', 'I\'m not able to provide assistance', 'There’s no room for creativity'])
        break
      default:
        setContent([])
        break
    }
  }, [section])

  return (
    <form onSubmit={handleSubmit} className={className}>
      <FormControl component="fieldset" className='w-full md:w-[480px] lg:w-[520px]'>
        <RadioGroup
          aria-label="quiz"
          name="quiz-radio-group-2"
          onChange={handleCheckboxChange}
        >
          {content.map((label, index) => (
            <div className='border p-2 rounded-md m-1 w-auto md:w-full' key={index}>
              <FormControlLabel
                key={label}
                value={(index + 1).toString()}
                control={<Radio />}
                label={label}
                checked={(index + 1) === q3Value}
                labelPlacement='start'
              />
            </div>
          ))}
        </RadioGroup>
      </FormControl>
      <button
        disabled={isDisabled}
        className="quiz_form_button text-white !mt-4 !w-auto !px-4"
      >
        Finish and get your Branda!
      </button>
    </form>
  )
}
