import QuizSecondStepForm from '../../molecules/QuizSecondStepForm/QuizSecondStepForm'
import Steps from '../../molecules/Steps/Steps'
import React from 'react'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizSecondStep ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <QuizSecondStepForm handleShowPage={handleShowPage} className='flex flex-col relative visible_1 justify-center items-center gap-2 my-1'/>
    </div>
  )
}
