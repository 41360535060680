import QuizFirstStepForm from '../../molecules/QuizFirstStepForm/QuizFirstStepForm'
import QuizFirstStepTitle from '../../molecules/QuizFirstStepTitle/QuizFirstStepTitle'
import Steps from '../../molecules/Steps/Steps'
import React from 'react'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizFirstStep ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
      <ProgressBar value={0} className='w-full md:w-1/2 mx-auto mt-5'/>
      <QuizFirstStepTitle className='flex flex-col relative visible_1 justify-center items-center gap-5' />
      <QuizFirstStepForm handleShowPage={handleShowPage} className='flex flex-col relative visible_1 justify-center items-center gap-3 mt-5 mx-3 md:mx-auto' />
    </div>
  )
}
