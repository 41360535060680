import React from 'react'

interface Props {
  handleModalOpen?: () => void
  iconColor?: string
  className?: string
}

export default function AskBranda ({ handleModalOpen, iconColor, className }: Props) {
  return (
    <div className={`flex justify-end w-full ${className}`}>
      <button onClick={handleModalOpen}>
        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-zoom-question" width="20"
             height="20" viewBox="0 0 24 24" strokeWidth="1.5" stroke={iconColor ?? '#552bb1'} fill="none" strokeLinecap="round"
             strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"/>
          <path d="M21 21l-6 -6"/>
          <path d="M10 13l0 .01"/>
          <path d="M10 10a1.5 1.5 0 1 0 -1.14 -2.474"/>
        </svg>
      </button>
    </div>
  )
}
