import { type ApiClient } from '../ApiClient'
import { UserType } from '../../quiz/quizSlice'

export class UserFields {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  private getEndpointName (type: string, id: number) {
    switch (type) {
      case UserType.EMPLOYEE:
        return `employee/${id}/`
      case UserType.ENTREPRENEUR:
      case UserType.BUSINESS:
      default:
        return ''
    }
  }

  async get (type: string, id: number) {
    return await this.api.get<object>(
      this.getEndpointName(type, id)
    )
  }

  async update (type: string, id: number, data: object) {
    return await this.api.patch<object>(
      this.getEndpointName(type, id),
      data,
      true
    )
  }
}
