import { type ChangeEvent, type KeyboardEvent } from 'react'
import { setEmail } from '../../../app/quiz/quizSlice'
import { useAppDispatch } from '../../../app/hooks'

interface Props {
  type: string
  placeholder: string
  className: string
  setInput?: (value: string) => void
  email?: boolean
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  onSubmit?: () => void
  value?: string
}

export default function Input ({ type, placeholder, className, setInput, email, onChange, onSubmit }: Props) {
  const dispatch = useAppDispatch()

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    (email ?? false) ? dispatch(setEmail(e.target.value)) : setInput?.(e.target.value)
    if (onChange !== undefined && onChange !== null) {
      onChange(e)
    }
  }

  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && onSubmit !== undefined) {
      onSubmit()
    }
  }

  return (
    <input onKeyUp={handleKeyUp} onChange={handleInputChange} className={className} type={type} placeholder={placeholder} />
  )
}
