/* eslint-disable  */
import useMediaQuery from '@mui/material/useMediaQuery'
import {useTheme} from '@mui/material/styles'
import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import React, {useEffect, useState} from 'react'
import {PAGE, setBusinessTypeValue, setCurrentUser, setUserBrandName} from '../../../app/quiz/quizSlice'
import {professions, users} from '../../../app/services/Api'
import {useNavigate} from 'react-router-dom'
import {Autocomplete, TextField} from '@mui/material'
import {toast} from 'react-toastify'
import {ArrowDropDownIcon} from "@mui/x-date-pickers";

interface Props {
  className: string
}

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

interface BusinessType {
  id: number
  name: string
}

export default function BusinessAboutPageForm({className, handleShowPage}: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isDisabled, setIsDisabled] = useState(true)
  const navigate = useNavigate()
  const [availableProfessions, setAvailableProfessions] = useState<BusinessType[]>([])
  const [selectedBusinessType, setSelectedBusinessType] = useState<BusinessType | null>(null)
  const [brandNameValue, setBrandNameValue] = useState<string>('')
  const [selectedBusinessTypeValue, setSelectedBusinessTypeValue] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>('')
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const otherOption = {
    id: 9999,
    name: 'Other'
  }

  const handleCustomValue = () => {
    setInputValue(inputValue.trim())

    if (inputValue && !availableProfessions.some(option => option.name.toLowerCase() === inputValue.toLowerCase())) {
      setAvailableProfessions((businessTypes) => [...businessTypes, {'id': -1, 'name': inputValue}])
    }

    const option = availableProfessions.find((item) => {
      return item.name.toLowerCase() === inputValue.toLowerCase()
    })

    setSelectedBusinessType(option ?? null)
  }

  const handleInputChange = (event: any, value: any) => {
    setInputValue(value)
    dispatch(setBusinessTypeValue(value ?? null))
    setSelectedBusinessTypeValue(value ?? '')

    if (value !== '') {
      setIsInputFocused(false)
    }
  }

  const handleChange = (event: any, value: any) => {
    if (currentUser === null) {
      return
    }

    dispatch(setBusinessTypeValue(value ?? null))
    setSelectedBusinessTypeValue(value ?? '')
    setInputValue(value ?? '')

    const option = availableProfessions.find((item) => {
      return item.name === value
    })

    setSelectedBusinessType(option ?? null)

    setIsInputFocused(option !== null && option !== undefined && option.id === otherOption.id && value !== '')

    if (option) {
      if (option.id === otherOption.id) {
        setSelectedBusinessTypeValue('')
        setInputValue('')
        setSelectedBusinessType(null)
      } else {
        void users.updateRegisteredUser(currentUser?.id ?? 0, {
          business_type: option.id
        }, true).then(() => {
          dispatch(setCurrentUser({
            ...currentUser,
            business_type: option.id
          }))
        })
      }
    }
  }

  const handleBrandNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBrandNameValue(event.target.value)
  }

  const handleUpdateUser = (e: React.FormEvent) => {
    e.preventDefault()

    if (selectedBusinessType === null || selectedBusinessType.id === -1) {
      void professions.createBusinessType({
        name: inputValue,
        email: currentUser?.email
      }).then((response) => {
        handleGoingToNextStep(response.id)
      })
    } else {
      handleGoingToNextStep(selectedBusinessType?.id)
    }

    handleShowPage(PAGE.BUSINESS_MORE_INFO)
  }

  const handleGoingToNextStep = (businessTypeId: number | null) => {
    if (currentUser === null) {
      navigate('/sign-in')
      return
    }

    dispatch(setUserBrandName(brandNameValue))
    void users.updateRegisteredUser(currentUser.id, {
      business_type: businessTypeId,
      brand_name: brandNameValue,
      step_name: 'BusinessMoreInfo'
    }, true)
  }

  useEffect(() => {
    if (currentUser !== null) {
      void professions.getBusinessTypes(currentUser?.id ?? 0).then((response) => {
        let options = response;
        options.push(otherOption)

        setAvailableProfessions(options)

        const option = response.find((item: BusinessType) => {
          return item.id === currentUser.business_type
        })

        console.log(currentUser.business_type)
        dispatch(setBusinessTypeValue(option?.name ?? null))
        dispatch(setUserBrandName(currentUser.brand_name ?? null))
        setSelectedBusinessType(option ?? null)
        setSelectedBusinessTypeValue(option?.name ?? '')
        setInputValue(option?.name ?? '')
        setBrandNameValue(currentUser.brand_name ?? '')
        setIsDisabled((option?.name ?? null) === null || currentUser.brand_name === '')
      }).catch((error) => {
        toast.error('Error getting the business types, please try refreshing the page!')
        console.error(error)
      })
    }
  }, [])

  useEffect(() => {
    setIsDisabled(selectedBusinessTypeValue === '' || brandNameValue === '')
  }, [selectedBusinessTypeValue, brandNameValue])

  return (
    <div className={className}>
      <Autocomplete
        freeSolo={true}
        disablePortal
        forcePopupIcon={true}
        className={'mb-6'}
        id="combo-box-business-types"
        options={availableProfessions.map((profession) => profession.name)}
        value={selectedBusinessTypeValue}
        inputValue={inputValue}
        onChange={handleChange}
        popupIcon={<ArrowDropDownIcon />}
        renderInput={(params) =>
            <TextField
                {...params}
                focused={isInputFocused}
                label="Type or select an option"
                onChange={(e) => {
                  handleInputChange(e, e.target.value)
                }}
                onBlur={handleCustomValue}/>}
        sx={{
          width: isSmallerScreen ? '90%' : '50%',
          "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: isInputFocused ? 'firebrick' : '#1976d2',
          },
      }}
      />
      <TextField
        id="outlined-basic"
        placeholder={'Google, Toyota, Lululemon, etc.'}
        label="What's your company Brand name?"
        InputLabelProps={{
          shrink: true
        }}
        variant="outlined"
        value={brandNameValue}
        onChange={handleBrandNameChange}
        sx={{width: isSmallerScreen ? '90%' : '50%'}}
      />

      <button
        disabled={isDisabled}
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16 !mt-6'
      >
        Next
      </button>
    </div>
  )
}
