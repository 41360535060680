/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { Button, TextField } from '@mui/material'
import BackButton from 'components/atoms/BackButton/BackButton'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setProfessionPage } from 'app/quiz/adminSlice'
import { toast } from 'react-toastify'
import { professions } from 'app/services/Api'

interface AddProfessionProps {
  onAddProfession: (newProfession: Profession) => void
}

interface NewProfession {
  name: string
}

interface Profession {
  id: number
  name: string
}

export default function AddProfession ({ onAddProfession }: AddProfessionProps) {
  const dispatch = useAppDispatch()
  const professionPage = useAppSelector(state => state.adminFaq.professionPage)
  const [backPage, setBackPage] = useState('')
  const [isFormValid, setIsFormValid] = useState(false)

  const [newProfession, setNewProfession] = useState<NewProfession>({
    name: ''
  })

  const validateForm = () => {
    const isValid = Object.values(newProfession).every((value) => value !== '')
    setIsFormValid(isValid)
  }

  useEffect(() => {
    validateForm()
  }, [newProfession])

  const handleBackPage = (page: string) => {
    dispatch(setProfessionPage(page))

    if (professionPage === 'profession-admin-add') {
      setBackPage(page)
    }

    window.scrollTo(0, 0)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNewProfession((prev) => ({ ...prev, [name]: value }))
  }

  const handleAddProfession = async () => {
    try {
      const response = await professions.create(newProfession)
      toast.success('Profession added!')
      onAddProfession(response)
    } catch (error) {
      console.error('Error adding Profession:', error)
    } finally {
      setNewProfession({
        name: ''
      })
    }
    dispatch(setProfessionPage('profession-admin'))
  }

  return (
    <div className="flex items-center justify-start">
      <div className="md:w-2/3 bg-white rounded-3xl p-8">
        <BackButton
          backPage={backPage}
          handleBackPage={() => { handleBackPage('profession-admin') }}
          className="quiz_back_button block mb-8"
        />
        <div className="my-2  mb-10 text-xl font-medium">Add new Profession</div>
        <form>
          <div className="mb-4">
            <TextField
              label="Name"
              type="text"
              name="name"
              value={newProfession.name}
              onChange={handleInputChange}
              fullWidth
            />
          </div>
          <div className="text-center mt-8">
            <Button
              className="w-40 bg-fuchsia-800 text-white font-bold py-2 px-4 rounded"
              type="button"
              variant="outlined"
              onClick={handleAddProfession}
              disabled={!isFormValid}
            >
              Create
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}
