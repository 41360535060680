/* eslint-disable @typescript-eslint/no-misused-promises */
import { toast } from 'react-toastify'
import {
  clearAll,
  setCurrentUser
} from '../../../app/quiz/quizSlice'
import { users } from '../../../app/services/Api'
import LogOutProfileIcon from '../../../img/LogOutProfile.svg'
import { useAppDispatch } from '../../../app/hooks'
import { useNavigate } from 'react-router'

export default function ProfileLogOut () {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    } else {
      console.log('localStorage is not available')
    }
  }

  const handleLogOut = async () => {
    try {
      clearLocalStorage()
      await users.logOut(true)
      dispatch(setCurrentUser(null))
      dispatch(clearAll())
      navigate('/')
      toast.success('Successfully logged out!')
    } catch (err) {
      console.error('Error during logout:', err)
      toast.error('Failed to log out. Please try again.')
    }
  }

  return (
    <div className='flex justify-between items-center w-full gap-3'>
      <button className='flex justify-between w-full' onClick={async () => { await handleLogOut() }} >
        <h1 className='text-sm font-normal'>
          Log Out
        </h1>
        <img src={LogOutProfileIcon} alt="Profile Arrow Icon" />
      </button>
    </div>
  )
}
