/* eslint-disable */
import { ApiClient } from './ApiClient'
import { PreSignUpUsers } from './Requests/PreSignUpUsers'
import { Users } from './Requests/Users'
import { Auth } from './Requests/Auth'
import { Brandas } from './Requests/Brandas'
import { Notifications } from './Requests/Notifications'
import { Quiz } from './Requests/Quiz'
import { Chat } from './Requests/Chat'
import { ContactUs } from './Requests/ContactUs'
import { Faq } from './Requests/Faq'
import { Tasks } from './Requests/Tasks'
import { Profession } from './Requests/Profession'
import {UserFields} from "./Requests/UserFields";

const apiBaseUrl = process.env.REACT_APP_API_URL || 'https://api.mybranda.com/';

const api = new ApiClient(`${apiBaseUrl}api/v1/`)
const authApi = new ApiClient(apiBaseUrl)

// const api = new ApiClient('http://127.0.0.1:8000/api/v1/')
// const authApi = new ApiClient('http://127.0.0.1:8000/')

const users = new Users(api)
const userFields = new UserFields(api)
const tasks = new Tasks(api)
const auth = new Auth(authApi)
const notifications = new Notifications(authApi)
const preSignUpUser = new PreSignUpUsers(api)
const brandas = new Brandas(api)
const quiz = new Quiz(api)
const chats = new Chat(api)
const contactUs = new ContactUs(api)
const faqs = new Faq(api)
const professions = new Profession(api)

export { preSignUpUser, users, auth, brandas, quiz, chats, contactUs, faqs, tasks, notifications, professions, userFields}
