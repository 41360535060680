import Steps from '../../molecules/Steps/Steps'
import React, { useEffect, useState } from 'react'
import QuizSecondStepGenieTitle from 'components/molecules/QuizSecondStepTitle/QuizSecondStepGenieTitle'
import QuizSecondStepGenieForm from '../../molecules/QuizSecondStepForm/QuizSecondStepGenieForm'
import { quiz, users } from '../../../app/services/Api'
import {
  PAGE, setQuestionQ11,
  setQuestionQ21, setQuestionQ210, setQuestionQ211, setQuestionQ212,
  setQuestionQ22,
  setQuestionQ23,
  setQuestionQ24, setQuestionQ25, setQuestionQ26, setQuestionQ27, setQuestionQ28, setQuestionQ29,
  setSection
} from '../../../app/quiz/quizSlice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../app/hooks'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function QuizSecondStep ({ className, currentPage, handleShowPage }: Props) {
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const preSignedupUserId = useAppSelector(state => state.quizReducer.userPreSignupId)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const genie = useAppSelector(state => state.quizReducer.Genie)
  const Q1_1 = useAppSelector(state => state.quizReducer.Q1_1)
  const Q2_1 = useAppSelector(state => state.quizReducer.Q2_1)
  const Q2_2 = useAppSelector(state => state.quizReducer.Q2_2)
  const Q2_3 = useAppSelector(state => state.quizReducer.Q2_3)
  const Q2_4 = useAppSelector(state => state.quizReducer.Q2_4)
  const Q2_5 = useAppSelector(state => state.quizReducer.Q2_5)
  const Q2_6 = useAppSelector(state => state.quizReducer.Q2_6)
  const Q2_7 = useAppSelector(state => state.quizReducer.Q2_7)
  const Q2_8 = useAppSelector(state => state.quizReducer.Q2_8)
  const Q2_9 = useAppSelector(state => state.quizReducer.Q2_9)
  const Q2_10 = useAppSelector(state => state.quizReducer.Q2_10)
  const Q2_11 = useAppSelector(state => state.quizReducer.Q2_11)
  const Q2_12 = useAppSelector(state => state.quizReducer.Q2_12)

  useEffect(() => {
    const quizStep = currentUser?.quiz_step

    if (quizStep?.Q1_1 !== undefined && Q1_1 === '') {
      dispatch(setQuestionQ11(quizStep?.Q1_1))
    }

    if (quizStep?.Q2_1 !== undefined && Q2_1 === '') {
      dispatch(setQuestionQ21(quizStep?.Q2_1))
    }

    if (quizStep?.Q2_2 !== undefined && Q2_2 === '') {
      dispatch(setQuestionQ22(quizStep?.Q2_2))
    }

    if (quizStep?.Q2_3 !== undefined && Q2_3 === '') {
      dispatch(setQuestionQ23(quizStep?.Q2_3))
    }

    if (quizStep?.Q2_4 !== undefined && Q2_4 === '') {
      dispatch(setQuestionQ24(quizStep?.Q2_4))
    }

    if (quizStep?.Q2_5 !== undefined && Q2_5 === '') {
      dispatch(setQuestionQ25(quizStep?.Q2_5))
    }

    if (quizStep?.Q2_6 !== undefined && Q2_6 === '') {
      dispatch(setQuestionQ26(quizStep?.Q2_6))
    }

    if (quizStep?.Q2_7 !== undefined && Q2_7 === '') {
      dispatch(setQuestionQ27(quizStep?.Q2_7))
    }

    if (quizStep?.Q2_8 !== undefined && Q2_8 === '') {
      dispatch(setQuestionQ28(quizStep?.Q2_8))
    }

    if (quizStep?.Q2_9 !== undefined && Q2_9 === '') {
      dispatch(setQuestionQ29(quizStep?.Q2_9))
    }

    if (quizStep?.Q2_10 !== undefined && Q2_10 === '') {
      dispatch(setQuestionQ210(quizStep?.Q2_10))
    }

    if (quizStep?.Q2_11 !== undefined && Q2_11 === '') {
      dispatch(setQuestionQ211(quizStep?.Q2_11))
    }

    if (quizStep?.Q2_12 !== undefined && Q2_12 === '') {
      dispatch(setQuestionQ212(quizStep?.Q2_12))
    }
  }, [])

  const handleNextStep = () => {
    let preSignupId = 0
    if (preSignedupUserId === 0) {
      preSignupId = currentUser?.pre_signup_id ?? 0
    } else {
      preSignupId = preSignedupUserId
    }

    void quiz.getQuizSection({
      Q1_1,
      Q2_1,
      Q2_2,
      Q2_3,
      Q2_4,
      Q2_5,
      Q2_6,
      Q2_7,
      Q2_8,
      Q2_9,
      Q2_10,
      Q2_11,
      Q2_12,
      Genie: genie
    }, preSignupId).then((response) => {
      handleShowPage(PAGE.QUIZ_FINAL_STEP)
      dispatch(setSection(response.score.Section))
    }).catch((error) => {
      console.log(error)
    })

    void users.updateRegisteredUser(currentUser?.id ?? 0, { step_name: 'Step Three' }, true)
  }

  useEffect(() => {
    setIsDisabled(genie === '')
  }, [genie])

  return (
    <div className={className}>
      <div className='flex flex-col relative visible_1 justify-center items-center gap-2 my-1 mx-1 md:mx-1 lg:mx-3'>
        <Steps currentPage={currentPage}/>
        <ProgressBar value={5} className='w-full md:w-1/2 mx-auto mt-1'/>
        <QuizSecondStepGenieTitle className='flex flex-col justify-center items-center gap-2'/>
        <QuizSecondStepGenieForm className='flex w-full lg:w-auto'/>
        <button disabled={isDisabled} onClick={handleNextStep}
                className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center !mt-4 mb-7 h-16">
           Next
        </button>
      </div>
    </div>
  )
}
