import { LinearProgress, linearProgressClasses, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { EmployeeStatus, UserType } from '../../../app/quiz/quizSlice'

interface Props {
  value: number
  isRegistration?: boolean
  className: string
}

export function ProgressBar ({ value, isRegistration = false, className }: Props) {
  const user = useAppSelector((state) => state.quizReducer.currentUser)
  const [registrationMaxPages, setRegistrationMaxPages] = useState<number>(0)

  const getMaxPages = () => {
    if (user?.user_type === UserType.BUSINESS) {
      return 5
    }

    if (user?.user_type === UserType.EMPLOYEE) {
      if ([EmployeeStatus.EMPLOYED, EmployeeStatus.EMPLOYED_LOOKING].includes(user?.employee_status ?? '')) {
        return 6
      }

      return 5
    }

    return 4
  }

  useEffect(() => {
    setRegistrationMaxPages(getMaxPages())
  }, [user])

  const quizMaxPages = 6
  const maxPages = isRegistration ? registrationMaxPages : quizMaxPages

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: '#e3cffa',
      ...theme.applyStyles('dark', {
        backgroundColor: '#e3cffa'
      })
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: '#8847bf',
      ...theme.applyStyles('dark', {
        backgroundColor: '#8847bf'
      })
    }
  }))

  return (
      <div className={`flex justify-center items-center ${className}`}>
        <BorderLinearProgress className={'flex-1 mr-2'} variant={'determinate'} value={(value / maxPages) * 100}/>
        <span className='text-sm'>
          {value + 1} / {maxPages + 1}
        </span>
      </div>
  )
}
