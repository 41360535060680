/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import SidebarLinks from '../../molecules/SidebarLinks/SidebarLinks'
import { useAppSelector } from '../../../app/hooks'
import { useNavigate } from 'react-router-dom'
import { SubscriptionType } from '../../../app/quiz/quizSlice'

interface Props {
  className: string
  currentPage: string
}

export default function Sidebar ({ className, currentPage }: Props) {
  const navigate = useNavigate()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  return (
    <div className={className}>
      <SidebarLinks className='flex flex-col justify-evenly items-start p-5 bg-white w-56 h-86 rounded-3xl' />
      {(currentUser?.subscription[0] && currentUser.subscription[0]?.type === SubscriptionType.FREE) &&
        <div className='sidebar-upgrade rounded-3xl w-44 p-6 flex flex-col justify-center text-white'>
          <p className='text-center'>
              Get <b>25X More</b> generations with Premium!
          </p>
            <button onClick={() => {
              navigate('/subscription')
            }} className='rounded-2xl bg-black text-white py-2 px-6 mt-4'>
                Upgrade!
            </button>
        </div>
      }
    </div>
  )
}
