/* eslint-disable */
import { type ApiClient } from '../ApiClient'
import {User, UserType} from "../../quiz/quizSlice";

export class Tasks {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async getTask (taskId: number): Promise<any> {
    return await this.api.get<object>(`tasks/${taskId}/`)
  }

  async getViralTopics (userId: number, id?: number): Promise<any> {
    const today = new Date()
    const date = today.toISOString().split('T')[0]
    return await this.api.get<object>(`viral_topics/?user=${userId}&date=${date}${id !== undefined ? `&id=${id}` : ''}`)
  }

  async getAllTasks(taskType?: string): Promise<any> {
    const queryParam = taskType ? `?task_type=${taskType}` : '';
    return await this.api.get<object[]>(`tasks/${queryParam}`)
  }

  async getTopics(user: User, taskName: string | null = null, id?: number): Promise<any> {
    let baseUrl = 'topics'
    if (user.user_type === UserType.EMPLOYEE) {
      baseUrl = 'employee_topics'
    }

    return await this.api.get<object[]>(`${baseUrl}/${taskName !== null ? '?name=' + taskName : ''}${id !== undefined ? `?id=${id}` : ''}`)
  }

  async getMainCategories(user: User): Promise<any> {
    let baseUrl = 'main_categories'
    if (user.user_type === UserType.EMPLOYEE) {
      baseUrl = 'employee_main_categories'
    }

    return await this.api.get<object[]>(`${baseUrl}/`)
  }

  async getGenerationTasksByTopicId(topicId: number, user: User): Promise<any> {
    let baseUrl = 'generation_tasks'
    if (user.user_type === UserType.EMPLOYEE) {
      baseUrl = 'employee_tasks'
    }

    return await this.api.get<object[]>(`${baseUrl}/?category=${topicId}`)
  }

  async create(data: object, credentials?: boolean) {
    return await this.api.post<object>('tasks/', data, credentials)
  }

  async update (id: number, data: object): Promise<any> {
    return await this.api.put<object>(`tasks/${id}/`, data, true)
  }

  async delete (id: number, credentials?: boolean): Promise<any> {
    return await this.api.delete<object>(`tasks/${id}/`, { id }, credentials)
  }

  async updateHistoryItem (id: number, data: object): Promise<any> {
    return await this.api.patch<object>(`history/${id}/`, data, true)
  }

  async generateTopics(userId: number) {
    return await this.api.post<object>('generate_viral_topics/', {
      user: userId
    })
  }

  async generate(
    user: User,
    taskId: number,
    personalization: string,
    language: string,
    freeWriting: boolean,
    regenerationType: string | null = null,
    historyId: number | null = null,
    regenerationNote: string | null = null,
    viralTopicId: number | null = null
  ): Promise<any> {

    const data = {
      user: user.id,
      personalization: personalization,
      language: language,
      free_writing: freeWriting,
      regenerate_history: historyId,
      regeneration_type: regenerationType,
      regenerate_note: regenerationNote,
      task: null,
      employee_task: null,
      viral_topic: viralTopicId
    }

    if (user.user_type === UserType.EMPLOYEE) {
      // @ts-ignore
      data['employee_task'] = taskId
    } else {
      // @ts-ignore
      data['task'] = taskId
    }

    return await this.api.post<object[]>('generate_contents/', data)
  }
}
