import React from 'react'
import Heading from '../../atoms/Heading/Heading'
import { ReactComponent as QuizGoalsPageImg } from '../../../img/quiz-goals-page-img.svg'
interface Props {
  className: string
}

const QuizGoalsPageInfo: React.FC<Props> = ({ className }): JSX.Element => {
  return (
    <>
      <div className={className}>
        <QuizGoalsPageImg className="w-40 md:w-52" />
        <Heading
          className="quiz_page_two_h1 text-2xl font-medium mt-2"
          text="Choose your online goals"
        />
      </div>
    </>
  )
}

export default QuizGoalsPageInfo
