/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import QuizEmailPage from '../../organisms/QuizEmailPage/QuizEmailPage'
import QuizGoalsPage from '../../organisms/QuizGoalsPage/QuizGoalsPage'
import QuizFirstStep from '../../organisms/QuizFirstStep/QuizFirstStep'
import QuizThirdStep from '../../organisms/QuizThirdStep/QuizThirdStep'
import QuizSecondStep from '../../organisms/QuizSecondStep/QuizSecondStep'
import BackButton from '../../atoms/BackButton/BackButton'
import QuizGenderPage from '../../organisms/QuizGenderPage/QuizGenderPage'
import QuizProfessionPage from '../../organisms/QuizProfessionPage/QuizProfessionPage'
import QuizVerificationPage from '../../organisms/QuizVerificationPage/QuizVerificationPage'
import BrandaResult from '../../organisms/BrandaResult/BrandaResult'
import { useAppSelector } from '../../../app/hooks'
import QuizExpertise from 'components/organisms/QuizExpertisePage/QuizExpertise'
import { useNavigate } from 'react-router-dom'
import BusinessTypePage from '../../organisms/BusinessTypePage/BusinessTypePage'
import BusinessAboutPage from '../../organisms/BusinessAboutPage/BusinessAboutPage'
import BusinessMoreInfoPage from '../../organisms/BusinessMoreInfoPage/BusinessMoreInfoPage'
import BusinessKeywordsPage from '../../organisms/BusinessKeywordsPage/BusinessKeywordsPage'
import BrandaPreResultInfo from '../../molecules/BrandePreResult/BrandaPreResult'
import VideoWithTranscript from '../../organisms/VideoWithTranscript/VideoWithTranscript'
import QuizGenieStep from '../../organisms/QuizGenieStep/QuizGenieStep'
import { useDispatch } from 'react-redux'
import { EmployeeStatus, PAGE, setCurrentQuizPage, setCurrentUserStep, UserType } from '../../../app/quiz/quizSlice'
import QuizEmployeeProfessionPage from '../../organisms/QuizProfessionPage/QuizEmployeeProfessionPage'
import { EmployeeStatusPage } from '../../organisms/Employee/Status/EmployeeStatusPage'
import { EmployerPage } from '../../organisms/Employee/Employer/EmployerPage'
import { KeywordsPage } from '../../organisms/Employee/Keywords/KeywordsPage'

const videoUrl1 = 'QuizW.mp4'
const videoUrl2 = 'solo-kw-web.mp4'
const videoUrl3 = 'microbusiness-kw-web.mp4'
const transcriptContent1 = `
  <div style="font-size:12px;">
<p>
Hi! <br>
Branda here, your personal Branding coach. Thanks for signing up! <br> 
I'm so excited you’re here with us! <br>
Now, let's get to work!
</p>
    <br>
<p>
The first step to a winning brand is discovering your Branda. Your personal brand identity. <br>
For this, I will need your help! <br>
You see, your Brand Persona is based on your unique personality and goals! This is why I will need to get to know you better first... <br> 
Please answer our short research-based Branda quiz. It will take a few minutes... Make sure to answer all the questions. And don't worry! This is a one-time process! <br> 
 I will walk you through all the way! Whenever you need my guidance, simply click on the question mark icon, and I'll be there, explaining what to do. <br>
</p>
<br>
<p>
After you finish the quiz, I will send your Branda straight to your email, so you can learn more about your brand identity, share it with friends and save it for later! <br>
Ready? Let's start branding you up!
</p>
</div>
`
const transcriptContent2 = `
  <div>
    <p style="font-size:12px;">
    This part is pretty important, so I figured I’d jump in and make sure you do it perfectly. <br> <br>
  Please choose five keywords that specify your expertise and the main themes you would like to discuss in your social media posts. I’ve already prepared many relevant suggestions for you! <br>
  Simply click on the keywords that best describe the topics you’d like to write about. Then click on the + icon to add them to the list. <br>
  Make sure to choose topics that are based both on your professional expertise and your personal interests. <br>
  Let’s take me, for example. <br>
  As you already know, MY profession is a Personal Branding coach, and I’m highly experienced with the following topics: <br>
  Online Persona building, AI , and Content Writing. These are my professional keywords. However, I would also add one or more general topics that I’d like to discuss, such as Self-Empowerment and motivation.  But this is me... <br>
  Now it’s time you think about yours. <br> <br>
  Couldn’t find 5 matching keywords? No worries. You can also write your own topics in the open text field and press Enter! <br>
  Choose wisely! These keywords will stay with you for a while... <br> <br>
  Good Luck! <br>
  You’ve got this!
    </p>
  </div>
`

const transcriptContent3 = `
  <div>
    <p style="font-size:12px;">
This part is pretty important, so I figured I’d jump in and make sure you do it perfectly.<br><br>
Please choose the main discussion topics you want to discuss in your social media posts. I’ve already prepared many relevant suggestions for you!<br>
Simply click on the keywords that best describe the topics you’d like to write about.<br><br>
As a Business owner, the way to choose the best topics is based on three principles:<br>
1. Choose the topics that are based on your business’s-industry trends<br>
2. Consider your own professional expertise and topics you have knowledge of and can add more insights about.<br>
3. Pick at least one topic that matches your personal interests or values.<br><br>
Remember, our work here is to build your individual online persona; it’s not just about your business. It’s about YOU. We will promote your business indirectly by crafting meaningful content that brings value to your audience!<br>
Couldn’t find matching keywords? No worries!<br>
You can also write your own topics in the open text field!<br>
Choose wisely!<br>
These keywords will stay with you for a while...<br><br>
Good Luck!<br>
You’ve got this!
    </p>
  </div>
`
export default function Quiz (): JSX.Element {
  const dispatch = useDispatch()
  const videosByPage: Record<string, { videoUrl: string, transcriptUrl: string, title: string }> = {}
  videosByPage[PAGE.GOALS] = { videoUrl: videoUrl1, transcriptUrl: transcriptContent1, title: 'Welcome to myBranda!' }
  videosByPage[PAGE.PROFESSION] = { videoUrl: videoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
  videosByPage[PAGE.SOLO_KEYWORDS] = { videoUrl: videoUrl2, transcriptUrl: transcriptContent2, title: 'How to choose my perfect keywords?' }
  videosByPage[PAGE.BUSINESS_KEYWORDS] = { videoUrl: videoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }
  videosByPage[PAGE.BUSINESS_MORE_INFO] = { videoUrl: videoUrl3, transcriptUrl: transcriptContent3, title: 'How to choose my perfect keywords?' }

  const currentPage = useAppSelector(state => state.quizReducer.currentPage)
  const [isModalOpen, setModalOpen] = useState<boolean>(true)
  const [backPage, setBackPage] = useState('')
  const isBusiness = useAppSelector((state) => state.quizReducer.isBusiness)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const navigate = useNavigate()

  const currentVideo = videosByPage[currentPage] || []

  const handleCloseModal = (): void => {
    setModalOpen(false)
  }

  const handleModalOpen = (): void => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (currentUser === null) {
      dispatch(setCurrentQuizPage(PAGE.EMAIL))
      return
    }

    if (currentUser?.quiz_step?.step_name === 'Goals') {
      dispatch(setCurrentQuizPage(PAGE.GOALS))
      dispatch(setCurrentUserStep(PAGE.GOALS))
    } else if (currentUser?.quiz_step?.step_name === 'Gender') {
      dispatch(setCurrentQuizPage(PAGE.GENDER))
      dispatch(setCurrentUserStep(PAGE.GENDER))
    } else if (currentUser?.quiz_step?.step_name === 'BusinessType') {
      dispatch(setCurrentQuizPage(PAGE.BUSINESS_TYPE))
      dispatch(setCurrentUserStep(PAGE.BUSINESS_TYPE))
    } else if (currentUser?.quiz_step?.step_name === 'BusinessAbout') {
      dispatch(setCurrentQuizPage(PAGE.BUSINESS_ABOUT))
      dispatch(setCurrentUserStep(PAGE.BUSINESS_ABOUT))
    } else if (currentUser?.quiz_step?.step_name === 'BusinessMoreInfo') {
      dispatch(setCurrentQuizPage(PAGE.BUSINESS_MORE_INFO))
      dispatch(setCurrentUserStep(PAGE.BUSINESS_MORE_INFO))
    } else if (currentUser?.quiz_step?.step_name === 'BusinessKeywords') {
      dispatch(setCurrentQuizPage(PAGE.BUSINESS_KEYWORDS))
      dispatch(setCurrentUserStep(PAGE.BUSINESS_KEYWORDS))
    } else if (currentUser?.quiz_step?.step_name === 'Profession') {
      dispatch(setCurrentQuizPage(PAGE.PROFESSION))
      dispatch(setCurrentUserStep(PAGE.PROFESSION))
    } else if (currentUser?.quiz_step?.step_name === 'Employee Profession') {
      dispatch(setCurrentQuizPage(PAGE.EMPLOYEE_PROFESSION))
      dispatch(setCurrentUserStep(PAGE.EMPLOYEE_PROFESSION))
    } else if (currentUser?.quiz_step?.step_name === 'Employee Status') {
      dispatch(setCurrentQuizPage(PAGE.EMPLOYEE_STATUS))
      dispatch(setCurrentUserStep(PAGE.EMPLOYEE_STATUS))
    } else if (currentUser?.quiz_step?.step_name === 'Employee Employer') {
      dispatch(setCurrentQuizPage(PAGE.EMPLOYEE_EMPLOYER))
      dispatch(setCurrentUserStep(PAGE.EMPLOYEE_EMPLOYER))
    } else if (currentUser?.quiz_step?.step_name === 'Employee Keywords') {
      dispatch(setCurrentQuizPage(PAGE.EMPLOYEE_KEYWORDS))
      dispatch(setCurrentUserStep(PAGE.EMPLOYEE_KEYWORDS))
    } else if (currentUser?.quiz_step?.step_name === 'Keywords') {
      dispatch(setCurrentQuizPage(PAGE.SOLO_KEYWORDS))
      dispatch(setCurrentUserStep(PAGE.SOLO_KEYWORDS))
    } else if (currentUser?.quiz_step?.step_name === 'Step One') {
      dispatch(setCurrentQuizPage(PAGE.QUIZ_FIRST_STEP))
      dispatch(setCurrentUserStep(PAGE.QUIZ_FIRST_STEP))
    } else if (currentUser?.quiz_step?.step_name === 'Step Two') {
      dispatch(setCurrentQuizPage(PAGE.QUIZ_SECOND_STEP))
      dispatch(setCurrentUserStep(PAGE.QUIZ_SECOND_STEP))
    } else if (currentUser?.quiz_step?.step_name === 'Genie') {
      dispatch(setCurrentQuizPage(PAGE.QUIZ_GENIE_STEP))
      dispatch(setCurrentUserStep(PAGE.QUIZ_GENIE_STEP))
    } else if (currentUser?.quiz_step?.step_name === 'Step Three') {
      dispatch(setCurrentQuizPage(PAGE.QUIZ_FINAL_STEP))
      dispatch(setCurrentUserStep(PAGE.QUIZ_FINAL_STEP))
    } else if (currentUser?.quiz_step?.step_name === 'Page Result') {
      dispatch(setCurrentQuizPage('pageBrandaResult2'))
      dispatch(setCurrentUserStep('pageBrandaResult2'))
    } else if (currentUser?.quiz_step?.step_name === 'Page Thank You') {
      dispatch(setCurrentQuizPage('pageBrandaThankYou'))
      dispatch(setCurrentUserStep('pageBrandaThankYou'))
    }
  }, [currentUser])

  useEffect(() => {
    if (currentPage === PAGE.GOALS) {
      setBackPage(PAGE.VERIFICATION_CODE)
    } else if (currentPage === PAGE.GENDER) {
      setBackPage(PAGE.GOALS)
    } else if (currentPage === PAGE.PROFESSION) {
      setBackPage(PAGE.BUSINESS_TYPE)
    } else if (currentPage === PAGE.BUSINESS_TYPE) {
      setBackPage(PAGE.GENDER)
    } else if (currentPage === PAGE.BUSINESS_ABOUT) {
      setBackPage(PAGE.BUSINESS_TYPE)
    } else if (currentPage === PAGE.BUSINESS_MORE_INFO) {
      setBackPage(PAGE.BUSINESS_ABOUT)
    } else if (currentPage === PAGE.BUSINESS_KEYWORDS) {
      setBackPage(PAGE.BUSINESS_MORE_INFO)
    } else if (currentPage === PAGE.QUIZ_FIRST_STEP) {
      if (currentUser?.user_type === UserType.ENTREPRENEUR) {
        setBackPage(PAGE.SOLO_KEYWORDS)
      } else if (currentUser?.user_type === UserType.BUSINESS) {
        setBackPage(PAGE.BUSINESS_KEYWORDS)
      } else {
        setBackPage(PAGE.EMPLOYEE_KEYWORDS)
      }
    } else if (currentPage === PAGE.QUIZ_SECOND_STEP) {
      setBackPage(PAGE.QUIZ_FIRST_STEP)
    } else if (currentPage === PAGE.QUIZ_GENIE_STEP) {
      setBackPage(PAGE.QUIZ_SECOND_STEP)
    } else if (currentPage === PAGE.QUIZ_FINAL_STEP) {
      setBackPage(PAGE.QUIZ_GENIE_STEP)
    } else if (currentPage === PAGE.SOLO_KEYWORDS) {
      setBackPage(isBusiness ? PAGE.BUSINESS_MORE_INFO : PAGE.PROFESSION)
    } else if (currentPage === PAGE.EMPLOYEE_PROFESSION) {
      setBackPage(PAGE.BUSINESS_TYPE)
    } else if (currentPage === PAGE.EMPLOYEE_STATUS) {
      setBackPage(PAGE.EMPLOYEE_PROFESSION)
    } else if (currentPage === PAGE.EMPLOYEE_EMPLOYER) {
      setBackPage(PAGE.EMPLOYEE_STATUS)
    } else if (currentPage === PAGE.EMPLOYEE_KEYWORDS) {
      if (currentUser?.employee_status === EmployeeStatus.UNEMPLOYED) {
        setBackPage(PAGE.EMPLOYEE_STATUS)
      } else {
        setBackPage(PAGE.EMPLOYEE_EMPLOYER)
      }
    }

    if (!currentUser && backPage) {
      navigate('/sign-in')
    }

    if (currentPage) {
      setModalOpen([PAGE.GOALS].includes(currentPage))
    }

    localStorage.setItem('quizCurrentPage', currentPage)
  }, [currentPage])

  const handleShowPage = (page: string): void => {
    dispatch(setCurrentQuizPage(page))
    window.scrollTo(0, 0)
  }

  const handleBackPage = (page: string): void => {
    dispatch(setCurrentQuizPage(page))
    window.scrollTo(0, 0)
  }

  let button = null
  if (![
    PAGE.EMAIL,
    PAGE.VERIFICATION_CODE,
    PAGE.GOALS,
    PAGE.QUIZ_SECOND_STEP,
    'pageBrandaThankYou',
    'pageBrandaResult',
    'pageBrandaPreResult'
  ].includes(currentPage)) {
    button = (
      <BackButton
        backPage={backPage}
        handleBackPage={handleBackPage}
        className="quiz_back_button z-10 block absolute !left-[20px] md:!left-[35px]"
      />
    )
  }

  return (
    <>
      {button != null && (
        <div className="relative">
          {button}
        </div>
      )}

      {isModalOpen && currentVideo && (
        <VideoWithTranscript
          title={currentVideo.title}
          videoUrl={currentVideo.videoUrl}
          transcript={currentVideo.transcriptUrl}
          handleCloseModal={handleCloseModal}
        />
      )}

      {currentPage === PAGE.EMAIL && <QuizEmailPage
        handleShowPage={handleShowPage}
        className={'quiz_email_page visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-12 mb-10 md:mb-28'}
      />}

      {currentPage === PAGE.VERIFICATION_CODE && <QuizVerificationPage
        handleShowPage={handleShowPage}
        className={'quiz_verification_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'}
      />}

      {currentPage === PAGE.GOALS && <QuizGoalsPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'quiz_goals_page visible relative flex flex-col justify-center items-center bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 p-4 pb-20  mb-28'}
        handleModalOpen={handleModalOpen}
      />}

      {currentPage === PAGE.GENDER && <QuizGenderPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'quiz_gender_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full p-4 basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-28'}
      />}

      {currentPage === PAGE.BUSINESS_TYPE && <BusinessTypePage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white flex flex-col justify-center items-center rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-10 md:mb-28 px-4'}
      />}

      {currentPage === PAGE.BUSINESS_ABOUT && <BusinessAboutPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white flex flex-col justify-center items-center rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-10 md:mb-28 p-4'}
      />}

      {currentPage === PAGE.BUSINESS_MORE_INFO && <BusinessMoreInfoPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white rounded-3xl flex flex-col justify-center items-center relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28 p-4'}
        handleModalOpen={handleModalOpen}
      />}

      {currentPage === PAGE.PROFESSION && <QuizProfessionPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'quiz_profession_page visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24 p-4'}
        handleModalOpen={handleModalOpen}
      />}

      {currentPage === PAGE.EMPLOYEE_PROFESSION && <QuizEmployeeProfessionPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24 p-4'}
        handleModalOpen={handleModalOpen}
      />}

      {currentPage === PAGE.EMPLOYEE_STATUS && <EmployeeStatusPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-24 p-4'}
      />}

      {currentPage === PAGE.EMPLOYEE_EMPLOYER && <EmployerPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-24 p-4'}
      />}

      {currentPage === PAGE.EMPLOYEE_KEYWORDS && <KeywordsPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-24 p-4'}
      />}

      {currentPage === PAGE.SOLO_KEYWORDS && <QuizExpertise
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'quiz_expertise_page visible px-4 md:px-0 flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-14 mb-28'}
      />}

      {currentPage === PAGE.BUSINESS_KEYWORDS && <BusinessKeywordsPage
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible px-4 md:px-0 bg-white rounded-3xl flex flex-col justify-center items-center relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28 p-4'}
        handleModalOpen={handleModalOpen}
      />}

      {currentPage === PAGE.QUIZ_FIRST_STEP && <QuizFirstStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white md:px-0 rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-14 px-4 pb-20 mb-24'}
      />}

      {currentPage === PAGE.QUIZ_SECOND_STEP && <QuizSecondStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-14 px-4 mb-24'}
      />}

      {currentPage === PAGE.QUIZ_GENIE_STEP && <QuizGenieStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-14 mb-24 px-4'}
      />}

      {currentPage === PAGE.QUIZ_FINAL_STEP && <QuizThirdStep
        handleShowPage={handleShowPage}
        currentPage={currentPage}
        className={'quiz_third_step visible relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-14 pb-10 mb-24 px-4'}
      />}

      {currentPage === 'pageBrandaPreResult' && <BrandaPreResultInfo
          handleShowPage={handleShowPage}
          className='branda_result_page_2 visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full pb-20 pt-10 left-0 md:mx-auto md:mt-0 mb-24'
        />
      }

      {currentPage === 'pageBrandaResult' && <BrandaResult
          handleShowPage={handleShowPage}
          className='branda_result visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
        />
      }
    </>
  )
}
