import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import {
  setIsFromBrandaSuggestion,
  setSelectedContentHistory,
  setSelectedTopic,
  type Topic
} from '../../../app/quiz/ContentGeneratorSlice'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

interface Props {
  topic: Topic
  index: number
  className?: string
  name?: string
  clickEvent?: () => void
}

export default function PremiumTopicBox ({ className, clickEvent, name, topic, index }: Props) {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const topicColors = [
    '#efdb89',
    '#b6cbf6',
    '#b3baf3',
    '#c2b4f6',
    '#d6b2f8',
    '#f8b3e3',
    '#f8aebb',
    '#fac3cc',
    '#f4d8ae',
    '#f4ebb7',
    '#e9f4b7',
    '#d9f4b7',
    '#e3f4b7'
  ]

  const handleTopicSelection = (topic: Topic, isSuggestion: boolean = false) => {
    dispatch(setSelectedContentHistory(null))
    dispatch(setIsFromBrandaSuggestion(isSuggestion))
    dispatch(setSelectedTopic(topic))
    navigate('/content-generator/' + topic.id)
  }

  return (
    <Tooltip
      title={topic.description ?? ''}>
      <div
        onClick={() => {
          if (clickEvent === undefined) {
            handleTopicSelection(topic)
          } else {
            clickEvent()
          }
        }}
        className={`${className} cursor-pointer font-medium relative hover:opacity-85 inline-flex flex-col justify-center items-center text-center p-3 bg-[${topicColors[index]}] rounded-xl h-28 w-[45%] md:w-48`}>
          {name ?? topic.name}
      </div>
    </Tooltip>
  )
}
