import Steps from '../../../molecules/Steps/Steps'
import { ProgressBar } from '../../../atoms/ProgressBar/ProgressBar'
import React, { useEffect, useState } from 'react'
import { Chip, TextField } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { users } from '../../../../app/services/Api'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { EmployeeStatus, PAGE, setCurrentUser } from '../../../../app/quiz/quizSlice'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export function KeywordsPage ({ className, currentPage, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const [isDisabled, setIsDisabled] = useState(true)
  const [inputValue, setInputValue] = useState<string>('')
  const [keywords, setKeywords] = useState<string[]>([])
  const [kwInspirations, setKwInspirations] = useState<string[]>([])
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  useEffect(() => {
    if (currentUser === null) {
      return
    }

    setIsDisabled(keywords.length === 0)

    void users.updateRegisteredUser(currentUser?.id ?? 0, {
      employee_keywords: keywords.join(', ')
    }, true).then(() => {
      dispatch(setCurrentUser({
        ...currentUser,
        employee_keywords: keywords.join(', ')
      }))
    })
  }, [keywords])

  useEffect(() => {
    void users.getKeywordPrediction(true)
      .then((response) => {
        setKwInspirations(response.prediction.split(', '))
      })

    if (currentUser?.employee_keywords !== null && currentUser?.employee_keywords !== '') {
      setKeywords(currentUser?.employee_keywords.split(', ') ?? [])
    }
  }, [])

  const handleSuggestedKeywordAdd = (label: string) => {
    if (keywords.includes(label)) {
      return
    }

    setKeywords([...keywords, label])
  }

  const addKeyword = (): void => {
    const trimmedValue = inputValue.trim()
    if (trimmedValue.length > 0) {
      const newKeywords = trimmedValue.split(',').map(word => word.trim())
      setKeywords([...keywords, ...newKeywords])
      setInputValue('')
    }
  }

  const handleChipDelete = (chipToDelete: string) => () => {
    setKeywords((chips) => chips.filter((chip) => chip !== chipToDelete))
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ',') {
      addKeyword()
    }
  }

  const handleNextPage = () => {
    if (currentUser === null) {
      return
    }

    void users.updateRegisteredUser(currentUser.id, {
      step_name: 'Step One'
    }).then(() => {
      handleShowPage(PAGE.QUIZ_FIRST_STEP)
    })
  }

  return (
      <div className={className}>
          <Steps currentPage={currentPage}/>
          <ProgressBar value={currentUser?.employee_status === EmployeeStatus.UNEMPLOYED ? 5 : 6} isRegistration={true} className='w-full md:w-1/2 mx-auto mt-0'/>

          <h1 className="main_header_home text-xl md:text-2xl font-medium mt-0 md:mt-4 text-center visible_1">What would you like to talk about?</h1>
          <p
              className="md:inline-block text-sm md:text-base px-5 py-2 md:p-0 text-center visible_1">
              Choose your discussion topics from the list or write your own below!
          </p>

          <div className='bg-gray-100 rounded-2xl p-2.5 w-full md:w-1/2 mt-0 md:mt-3 md:mb-4'>
              <div className='flex justify-center flex-wrap h-32 overflow-auto'>
                  {kwInspirations.map((keyword, index) => (
                      <div key={index} className="m-1">
                          <Chip sx={{
                            opacity: keywords.includes(keyword) ? 0.5 : 1,
                            cursor: keywords.includes(keyword) ? 'default' : 'pointer',
                            color: '#000',
                            backgroundColor: '#ffffff',
                            borderColor: '#ccc',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                            fontSize: '11px'
                          }}
                                id={`kw-${keyword}`} label={keyword}
                                onClick={() => {
                                  handleSuggestedKeywordAdd(keyword)
                                }}/>
                      </div>
                  ))}
              </div>
          </div>

          <div className="flex flex-wrap justify-center my-3 w-full md:w-1/2">
              {keywords.map((keyword, index) => (
                  <div key={index} className="m-1">
                      <Chip label={keyword} onDelete={handleChipDelete(keyword)}/>
                  </div>
              ))}
          </div>

          <div className="flex flex-col visible_1 md:mt-4">
              <div className="flex flex-col">
                  <TextField
                      label='Write your own topics'
                      placeholder='This is optional'
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      value={inputValue}
                      className='w-full md:w-96'
                      onKeyDown={(e) => {
                        handleKeyDown(e)
                      }}

                      onChange={(e) => {
                        if (e.target.value === ',') {
                          return
                        }

                        setInputValue(e.target.value)
                      }}
                      InputProps={{
                        style: {
                          borderRadius: '8px'
                        },
                        endAdornment: (
                          <button
                              type="button"
                              className={'rounded-lg text-sm hover:bg-purple-100 w-16 h-8 border-2 border-purple-300'}
                              onClick={addKeyword}
                          >
                              Add
                          </button>
                        )
                      }}
                  />
                  <span
                      className='text-center text-xs mt-2'>Separate each term with comma or press Add.</span>
              </div>
          </div>

          <button onClick={handleNextPage}
                  disabled={isDisabled}
                  className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative md:h-16 !mt-5'>
              Next
          </button>
      </div>
  )
}
