interface Props {
  className: string
}

export default function QuizSecondStepGenieTitle ({ className }: Props) {
  return (
    <div className={className}>
        <p className='main_header_home text-lg md:text-xl text-center mt-2 mb-2 w-full md:w-4/5'>If a genie granted you one wish what would you choose?</p>
    </div>
  )
}
