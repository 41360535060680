import React, { useRef } from 'react'
import Footer from 'components/organisms/Footer/Footer'
import NavBar from 'components/organisms/NavBar/NavBar'
import HeadersNonSignInUsers from 'components/molecules/Headers/HeadersNonSignInUsers'
import ScrollTopOnReload from 'app/services/ScrollTopOnReload'

interface Props {
  className: string
}

export default function HomeNonSignedInUsers ({ className }: Props) {
  const targetDivRef = useRef<HTMLDivElement | null>(null)

  const scrollToTargetDiv = () => {
    if (targetDivRef.current != null) {
      targetDivRef.current.scrollIntoView({
        behavior: 'smooth'
      })
    }
  }

  return (
    <div className={className}>
      <ScrollTopOnReload />
      <NavBar transparentBg={true} className='relative flex flex-row justify-between items-center p-5 pt-3 h-[116px] align-center -mb-5 sm:-mb-2' titleClassName='text-white'/>
        <div className={'flex flex-col h-[90vh] md:h-full'}>
          <HeadersNonSignInUsers className='flex-1 flex justify-between md:justify-center flex-col align-center home-page' onScrollDown={scrollToTargetDiv} />
          <Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 pb-10 p-3 pt-4 md:p-3 bg-white' />
        </div>
    </div>
  )
}
