/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect } from 'react'
import 'App.css'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import HomePage from 'pages/HomePage'
import QuizPage from 'pages/QuizPage'
import SignInForm from 'components/molecules/SignInForm/SignInForm'
import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setCurrentUser } from 'app/quiz/quizSlice'
import ResetPassword from 'components/molecules/SignInForm/ResetPassword'
import { users } from 'app/services/Api'
import ResetPasswordConfirm from 'components/molecules/SignInForm/ResetPasswordConfirm'
import ScrollToTop from 'app/services/ScrollToTop'
import HomeSubscriptionPage from 'pages/HomeSubscriptionPage'
import HomeNonSignedInUsers from 'components/templates/Home/Home-NonSignedInUsers'
import PrivacyPage from 'pages/PrivacyPage'
import TermsOfServicePage from 'pages/TermsOfServicePage'
import UnSubscribe from 'components/molecules/UnSubscribe/UnSubscribe'
import AboutUs from 'components/templates/AboutUs/AboutUs'
import { ProtectedRoute } from 'app/services/ProtectedRoute'
import { setCredits } from './app/quiz/userProfile'

function App (): JSX.Element {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)
  const pathLocation = useLocation().pathname

  const getCookie = (cookieName: string) => {
    const cookies = document.cookie.split('; ')
    const cookie = cookies.find((c) => c.startsWith(`${cookieName}=`))
    return cookie ? cookie.split('=')[1] : null
  }

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      await users.getCurrentUser(true).then(response => {
        if (!response.response) {
          if (getCookie('os') !== null) {
            users.updateUser(response.id, { fcm_token: getCookie('firebase-token') })
              .catch(() => {})
          }

          dispatch(setCurrentUser(response))

          void users.getRemainingCredits().then((response) => {
            dispatch(setCredits(response.credits))
          })
        } else {
          dispatch(setCurrentUser(null))
          dispatch(setCredits(0))
        }
      })
        .catch(err => {
          console.log(err)
          dispatch(setCurrentUser(null))
          dispatch(setCredits(0))
          localStorage.clear()
        })
    }
    void getUser()
  }, [dispatch])

  if (currentUser && currentUser.has_finished_steps && pathLocation === '/quiz') {
    return <Navigate to="/content-generator" replace />
  }

  return (
    <div className="App">
      <ScrollToTop />
      <ToastContainer position="top-center"
        autoClose={1200}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <Routes>
        <Route path='/' element={<HomeNonSignedInUsers className={'relative'} />} />
        <Route path='/dashboard' element={
          <ProtectedRoute redirectTo='/'>
            <HomePage/>
          </ProtectedRoute>
        } />
        <Route path='/privacy' element={<PrivacyPage/>} />
        <Route path='/terms' element={<TermsOfServicePage/>} />
        <Route path='*' element={<QuizPage />} />
        <Route path='/subscription-home' element={<HomeSubscriptionPage />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/subscription-website' element={<HomeSubscriptionPage />} />
        <Route path='/sign-in' element={<SignInForm />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/unsubscribe/:token' element={<UnSubscribe />} />
        <Route path='/password/reset/confirm/:uid/:token' element={<ResetPasswordConfirm />} />
      </Routes>
    </div>
  )
}

export default App
