import React from 'react'
import AskBranda from '../../atoms/Icon/AskBranda'
import Steps from '../../molecules/Steps/Steps'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'
import QuizEmployeeProfessionPageInfo from 'components/molecules/QuizProfessionPageInfo/QuizEmployeeProfessionPageInfo'
import QuizEmployeeProfessionForm from 'components/molecules/QuizProfessionPageForm/QuizEmployeeProfessionForm'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function QuizEmployeeProfessionPage ({ className, currentPage, handleShowPage, handleModalOpen }: Props) {
  return (
    <div className={className}>
      <div className='flex justify-end w-full custom-quiz-margin pt-5 pr-5'>
        <AskBranda handleModalOpen={handleModalOpen}/>
      </div>
      <Steps currentPage={currentPage} />
        <ProgressBar value={3} isRegistration={true} className='w-full md:w-1/2 mx-auto mt-0'/>
      <QuizEmployeeProfessionPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-2 md:mt-10'/>
      <QuizEmployeeProfessionForm handleShowPage={handleShowPage}
                              className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
