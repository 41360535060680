import React from 'react'
import BusinessTypePageInfo from '../../molecules/BusinessTypePage/BusinessTypePageInfo'
import BusinessTypePageForm from '../../molecules/BusinessTypePage/BusinessTypePageForm'
import Steps from '../../molecules/Steps/Steps'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function BusinessTypePage ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
        <ProgressBar value={2} isRegistration={true} className='w-full md:w-1/2 mx-auto my-2'/>
      <BusinessTypePageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 md:mt-4'/>
      <BusinessTypePageForm handleShowPage={handleShowPage} className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
