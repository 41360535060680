interface Props {
  className: string
  handleBackPage: (page: string) => void
  backPage: string
}

export default function BackButton ({ className, handleBackPage, backPage }: Props) {
  return (
    <a onClick={() => { handleBackPage(backPage) }} className={className}></a>
  )
}
