/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import React, { useEffect, useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Link } from 'react-router-dom'
import { users } from 'app/services/Api'
import { toast } from 'react-toastify'
import CancelSubscriptionModal from './Modals/CancelSubscriptionModal'

export default function SubscriptionPlan () {
  const payProBoosterId = 83053
  const payProStarId = 88434
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)
  const initialAutoRenewal = currentUser?.subscription[0]?.auto_renewal
  const [autoRenewal, setAutoRenewal] = useState(initialAutoRenewal)
  const [brandaType, setBrandaType] = useState('')
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false)
  const [isSubscriptionActive, setIsSubscriptionActive] = useState(currentUser?.subscription[0]?.is_active)
  const [isAutoRenewDisabled, setIsAutoRenewDisabled] = useState(false)

  const dispatch = useAppDispatch()

  const toggleAutoRenewal = async () => {
    setAutoRenewal(prevAutoRenewal => {
      const newAutoRenewal = !prevAutoRenewal
      let planId = 0
      if (brandaType === 'Brand Booster') {
        planId = payProBoosterId
      } else {
        planId = payProStarId
      }
      const autoRenewalId = newAutoRenewal ? 2 : 1
      users.updateUserBilling(planId, 'update', '', true, autoRenewalId)
      toast.success('Successfully updated your renewal!')
      return newAutoRenewal
    })
  }

  const toggleAutoRenewalAvailability = () => {
    if (currentUser?.subscription && currentUser?.subscription.length > 0) {
      const subscription = currentUser?.subscription[0]
      setIsAutoRenewDisabled(subscription.type === -1)
    }
  }

  const subscriptionPlanCancel = async (reason: string) => {
    setIsCancelModalOpen(false)
    setIsSubscriptionActive(false)
    let planId = 0
    if (brandaType === 'Brand Booster') {
      planId = payProBoosterId
    } else {
      planId = payProStarId
    }

    await users.updateUserBilling(planId, 'delete', reason, true)
    toast.warning('Successfully canceled your subscription!')
  }

  useEffect(() => {
    if (((currentUser?.subscription) != null) && currentUser?.subscription.length > 0) {
      const isUserInTrial = currentUser?.subscription[0].type === -1

      if (isUserInTrial) {
        setBrandaType('Brand Trial')
      } else {
        setAutoRenewal(currentUser?.subscription[0].auto_renewal)
        const userSubscription = currentUser?.subscription[0].type
        switch (userSubscription) {
          case 1:
            setBrandaType('Free')
            break
          case 2:
            setBrandaType('Premium')
            break
          case 3:
            setBrandaType('Pro')
            break
          default:
            setBrandaType('No Active Plan Yet')
            break
        }
      }
    } else {
      setBrandaType('No Active Plan Yet')
    }

    toggleAutoRenewalAvailability()
  }, [currentUser, dispatch])

  const changePaymentMethod = async () => {
    await users.changePaymentMethod(true)
    toast.success('Please follow the link in your email for further instructions!')
  }

  return (
    <div className='flex flex-col justify-center items-start md:justify-start md:items-start w-full gap-3'>
      <Header className='font-medium  text-xl md:text-3xl leading-7 text-black block mb-6 mt-7 md:4' text='Your Subscription' />
      <div className='flex flex-row items-start md:items-center gap-4 md:gap-6 mb-8 w-full'>
        <label className='font-thin text-slate-500 text-sm basis-3/6'>
          Your current plan:
          <input placeholder="Your Branda Plan" type='text' className='mt-2 md:mt-0 w-24 h-7 md:ml-2 text-xs m md:text-sm text-start md:text-center text-black font-bold ' value={`${brandaType}`} disabled />
        </label>
        <div className='flex justify-center md:justify-start basis-3/6'>
          <Link to='/subscription'>
            <button className="bg-slate-100 w-28 md:w-32 h-7 text-xs md:text-sm  text-start px-2  md:ml-0 capitalize text-violet-700 font-bold !underline">Review Plans</button>
          </Link>
        </div>
      </div>
      <div className='flex flex-row items-start md:items-center gap-4 md:gap-6 mb-8 w-full'>
        <label className='font-thin text-slate-500 text-sm basis-3/6'>
          Auto Renewal
        </label>
        <div className='relative flex flex-col md:flex-row items-center basis-3/6'>
    <button
        disabled={isAutoRenewDisabled}
        type='button'
        className={`bg-slate-100 w-28 md:w-32 h-7 text-sm text-center font-light capitalize text-black ${isAutoRenewDisabled ? 'cursor-not-allowed opacity-50' : ''}`}
        onClick={toggleAutoRenewal}
    >
        <div className="flex items-center">
            <span className="text-xs md:text-sm text-black px-2 mr-7 md:mr-9">Renew</span>
            <span>
                <span className="border border-black rounded px-1 flex items-center justify-center hover:bg-gray-200">
                    {autoRenewal && isSubscriptionActive
                      ? (
                        <svg className="w-4 h-5 fill-current text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
                        </svg>
                        )
                      : (
                        <span className="flex justify-center items-center w-4 h-5">❌</span>
                        )}
                </span>
            </span>
        </div>
    </button>
    <p className='ml-0 md:ml-4 pt-2 md:pt-0 w-24 md:w-32' style={{ fontSize: window.innerWidth < 768 ? '0.5rem' : '0.6rem' }}>
        Your subscription will {autoRenewal && isSubscriptionActive ? 'be' : <span><strong>NOT</strong> be</span>} automatically restored
    </p>
</div>

      </div>
      <div className='flex flex-row items-start md:items-center gap-4 md:gap-6 mb-8 w-full'>
        <label className='font-thin text-slate-500 text-sm basis-3/6'>
          Cancel Subscription
        </label>
        <div className='relative flex flex-col md:flex-row items-center basis-3/6'>
          <button type="button" className={`hover:bg-gray-200 bg-slate-100 px-2 w-28 md:w-32 h-7 text-xs md:text-sm text-start font-light capitalize text-black ${!isSubscriptionActive && 'opacity-50 cursor-not-allowed'}`}
            onClick={() => setIsCancelModalOpen(true)}
            disabled={!isSubscriptionActive}
          >
            {!isSubscriptionActive ? 'Canceled' : 'Cancel'}
          </button>
        </div>
      </div>
      <div className='flex flex-row items-start md:items-center gap-4 md:gap-6 mb-8 w-full'>
        <label className='font-thin text-slate-500 text-sm w-32 basis-3/6'>
          Ask for a refund
        </label>
        <div className='flex justify-center md:justify-start basis-3/6'>
          <Link
            to="/contact-us"
            state={{ customMessage: 'Hi! I have a question regarding my subscription 🙏' }}
          >
            <button
              type="submit"
              className="hover:bg-gray-200 bg-slate-100 px-2  w-28 md:w-32 h-7 text-xs md:text-sm text-start font-light capitalize text-black"
            >
              Contact Us
            </button>
          </Link>
        </div>
      </div>
      <div className='flex flex-row items-start md:items-center gap-4 md:gap-6 mb-8 w-full'>
        <label className='font-thin text-slate-500 text-sm basis-3/6'>
          Payment Method
        </label>
        <div className='relative flex flex-col md:flex-row items-center basis-3/6'>
          <button type="button" className="hover:bg-gray-200 bg-slate-100 w-28 md:w-32 h-7 text-xs md:text-sm text-start px-2 font-light capitalize text-black" onClick={changePaymentMethod}>Change Method</button>
        </div>
      </div>
      <CancelSubscriptionModal
        isOpen={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
        onConfirm={subscriptionPlanCancel}
      />
    </div>
  )
}
