/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setGenie } from '../../../app/quiz/quizSlice'
import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import { users } from 'app/services/Api'

interface Props {
  className: string
}

export default function QuizSecondStepGenieForm ({ className }: Props) {
  const dispatch = useAppDispatch()
  const genie = useAppSelector(state => state.quizReducer.Genie)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  useEffect(() => {
    if (currentUser?.quiz_step?.genie !== undefined && genie === '') {
      dispatch(setGenie(currentUser?.quiz_step?.genie))
    }
  }, [currentUser])

  const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setGenie(event.target.value))
    await users.updateRegisteredUser(currentUser?.id ?? 0, { genie: event.target.value }, true)
  }

  return (
    <div className={className}>
      <FormControl component="fieldset" className='w-full'>
        <RadioGroup
          aria-label="quiz"
          name="quiz-radio-group"
          value={genie}
          onChange={handleCheckboxChange}
          className='lg:!flex-row'
        >
          <div className="flex flex-col">
            <div className={'genie_quiz border p-2 rounded-md my-1 md:m-1'}>
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Be well-known as the best in my field"
                labelPlacement="start"
              />
            </div>
            <div className={'genie_quiz border p-2 rounded-md my-1 md:m-1'}>
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Solve a global problem"
                labelPlacement="start"
              />
            </div>
          </div>
          <div className="flex flex-col">
            <div className={'genie_quiz border p-2 rounded-md my-1 md:m-1'}>
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Become the head of a global corporation"
                labelPlacement="start"
              />
            </div>
            <div className={'genie_quiz border p-2 rounded-md my-1 md:m-1'}>
              <FormControlLabel
                value="4"
                control={<Radio />}
                label="Have the power to make people like me"
                labelPlacement="start"
              />
            </div>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  )
}
