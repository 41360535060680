/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useRef, useState } from 'react'
import { users } from '../../../app/services/Api'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  FormLabel,
  Radio,
  RadioGroup,
  FormControlLabel
} from '@mui/material'
import {
  setQuestionQ21,
  setQuestionQ22,
  setQuestionQ23,
  setQuestionQ24,
  setQuestionQ25,
  setQuestionQ26,
  setQuestionQ27,
  setQuestionQ28,
  setQuestionQ29,
  setQuestionQ210,
  setQuestionQ211,
  setQuestionQ212,
  PAGE
} from '../../../app/quiz/quizSlice'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'
import BackButton from '../../atoms/BackButton/BackButton'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

interface QuestionBoxProps {
  id: string
  question: string
  handleSelection: (event: React. ChangeEvent<HTMLInputElement>) => void
}

export default function QuizSecondStepForm ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const Q2_1 = useAppSelector(state => state.quizReducer.Q2_1)
  const Q2_2 = useAppSelector(state => state.quizReducer.Q2_2)
  const Q2_3 = useAppSelector(state => state.quizReducer.Q2_3)
  const Q2_4 = useAppSelector(state => state.quizReducer.Q2_4)
  const Q2_5 = useAppSelector(state => state.quizReducer.Q2_5)
  const Q2_6 = useAppSelector(state => state.quizReducer.Q2_6)
  const Q2_7 = useAppSelector(state => state.quizReducer.Q2_7)
  const Q2_8 = useAppSelector(state => state.quizReducer.Q2_8)
  const Q2_9 = useAppSelector(state => state.quizReducer.Q2_9)
  const Q2_10 = useAppSelector(state => state.quizReducer.Q2_10)
  const Q2_11 = useAppSelector(state => state.quizReducer.Q2_11)
  const Q2_12 = useAppSelector(state => state.quizReducer.Q2_12)
  const [visibleStepIndex, setVisibleStepIndex] = useState<number>(0)
  const [initialScrollIndex, setInitialScrollIndex] = useState<number>(0)

  useEffect(() => {
    const quizStep = currentUser?.quiz_step

    if (quizStep?.Q2_1 !== undefined && Q2_1 === '') {
      dispatch(setQuestionQ21(quizStep?.Q2_1))
    }

    if (quizStep?.Q2_2 !== undefined && Q2_2 === '') {
      dispatch(setQuestionQ22(quizStep?.Q2_2))
    }

    if (quizStep?.Q2_3 !== undefined && Q2_3 === '') {
      dispatch(setQuestionQ23(quizStep?.Q2_3))
    }

    if (quizStep?.Q2_4 !== undefined && Q2_4 === '') {
      dispatch(setQuestionQ24(quizStep?.Q2_4))
    }

    if (quizStep?.Q2_5 !== undefined && Q2_5 === '') {
      dispatch(setQuestionQ25(quizStep?.Q2_5))
    }

    if (quizStep?.Q2_6 !== undefined && Q2_6 === '') {
      dispatch(setQuestionQ26(quizStep?.Q2_6))
    }

    if (quizStep?.Q2_7 !== undefined && Q2_7 === '') {
      dispatch(setQuestionQ27(quizStep?.Q2_7))
    }

    if (quizStep?.Q2_8 !== undefined && Q2_8 === '') {
      dispatch(setQuestionQ28(quizStep?.Q2_8))
    }

    if (quizStep?.Q2_9 !== undefined && Q2_9 === '') {
      dispatch(setQuestionQ29(quizStep?.Q2_9))
    }

    if (quizStep?.Q2_10 !== undefined && Q2_10 === '') {
      dispatch(setQuestionQ210(quizStep?.Q2_10))
    }

    if (quizStep?.Q2_11 !== undefined && Q2_11 === '') {
      dispatch(setQuestionQ211(quizStep?.Q2_11))
    }

    if (quizStep?.Q2_12 !== undefined && Q2_12 === '') {
      dispatch(setQuestionQ212(quizStep?.Q2_12))
    }
  }, [])

  const updateUnansweredQuestions = (questionKey: string) => {
    setUnansweredQuestions(prevUnanswered => prevUnanswered.filter(question => question !== questionKey))
  }

  const handleFirstQuestion = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ21(event.target.value))
    updateUnansweredQuestions('Q2_1')
    void users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_1: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSecondQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ22(event.target.value))
    updateUnansweredQuestions('Q2_2')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_2: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleThirdQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ23(event.target.value))
    updateUnansweredQuestions('Q2_3')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_3: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleFourthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ24(event.target.value))
    updateUnansweredQuestions('Q2_4')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_4: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleFifthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ25(event.target.value))
    updateUnansweredQuestions('Q2_5')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_5: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSixthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ26(event.target.value))
    updateUnansweredQuestions('Q2_6')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_6: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleSeventhQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ27(event.target.value))
    updateUnansweredQuestions('Q2_7')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_7: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleEighthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ28(event.target.value))
    updateUnansweredQuestions('Q2_8')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_8: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleNinthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ29(event.target.value))
    updateUnansweredQuestions('Q2_9')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_9: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleTenthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ210(event.target.value))
    updateUnansweredQuestions('Q2_10')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_10: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleEleventhQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ211(event.target.value))
    updateUnansweredQuestions('Q2_11')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_11: event.target.value, step_name: 'Step Two' }, true)
  }

  const handleTwelfthQuestion = async (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setQuestionQ212(event.target.value))
    updateUnansweredQuestions('Q2_12')
    await users.updateRegisteredUser(currentUser?.id ?? 0, { Q2_12: event.target.value, step_name: 'Step Two' }, true)
  }

  const mappedValues: Record<string, string> = {
    Q2_1,
    Q2_2,
    Q2_3,
    Q2_4,
    Q2_5,
    Q2_6,
    Q2_7,
    Q2_8,
    Q2_9,
    Q2_10,
    Q2_11,
    Q2_12
  }

  const formRef = useRef<HTMLFormElement | null>(null)
  const [unansweredQuestions, setUnansweredQuestions] = useState<string[]>([])

  const scrollToUnansweredQuestion = () => {
    if (unansweredQuestions.length > 0 && formRef.current) {
      const firstUnansweredQuestionId = `radio-group-${unansweredQuestions[0]}`
      const firstUnansweredQuestion = formRef.current.querySelector(
        `div[id^="${firstUnansweredQuestionId}"]`
      )

      if (firstUnansweredQuestion) {
        firstUnansweredQuestion.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }

  const QuestionBox = (props: QuestionBoxProps) => {
    const { id, question, handleSelection } = props
    return (
      <div id={`radio-group-${id}`}
           className={`shadow-md mb-3 flex flex-col border justify-between p-4 !min-w-full items-center md:items-auto ${unansweredQuestions.includes(id) ? 'border-red-500' : ''}`}>
        <FormLabel id="radio-buttons-group-label"
                   className='flex items-center text-center min-w-full text-black text-wrap-balance quiz-four-text mb-4'>
          <span className='w-full text-black'>{question}</span>
        </FormLabel>
        <RadioGroup value={mappedValues[id]} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group" className='!w-full !flex !flex-row !justify-between'>
          <FormControlLabel style={{ marginRight: 0 }} value="1" label={<span className={'text-sm mdtext-base'}>Very true</span>} control={<Radio onChange={handleSelection}/>}/>
          <FormControlLabel style={{ marginRight: 0 }} value="2" label={<span className={'text-sm mdtext-base'}>Sometimes</span>} control={<Radio onChange={handleSelection}/>}/>
          <FormControlLabel style={{ marginRight: 0 }} value="3" label={<span className={'text-sm mdtext-base'}>Not at all</span>} control={<Radio onChange={handleSelection}/>}/>
        </RadioGroup>
      </div>
    )
  }

  const handleNextStep = () => {
    const emptyKeys = questionSteps[visibleStepIndex].filter((entry) => entry.value === '' || entry.value === null)
      .map((entry) => entry.id)

    setUnansweredQuestions(emptyKeys)
    scrollToUnansweredQuestion()

    if (emptyKeys.length > 0 || currentUser === null) {
      return
    }

    if ((questionSteps.length - 1) === visibleStepIndex) {
      void users.updateRegisteredUser(currentUser.id, {
        Q2_1,
        Q2_2,
        Q2_3,
        Q2_4,
        Q2_5,
        Q2_6,
        Q2_7,
        Q2_8,
        Q2_9,
        Q2_10,
        Q2_11,
        Q2_12,
        step_name: 'Genie'
      }).finally(() => {
        handleShowPage(PAGE.QUIZ_GENIE_STEP)
      })

      return
    }

    scrollToNext(false)
    setVisibleStepIndex(current => current + 1)
  }

  const handlePrevPage = () => {
    if (currentUser === null) {
      return
    }

    if (visibleStepIndex === 0) {
      void users.updateRegisteredUser(currentUser.id, {
        Q2_1,
        Q2_2,
        Q2_3,
        Q2_4,
        Q2_5,
        Q2_6,
        Q2_7,
        Q2_8,
        Q2_9,
        Q2_10,
        Q2_11,
        Q2_12,
        step_name: 'Step One'
      }).finally(() => {
        handleShowPage(PAGE.QUIZ_FIRST_STEP)
      })

      return
    }

    scrollToNext(true)
    setVisibleStepIndex(current => current - 1)
  }

  useEffect(() => {
    if (visibleStepIndex === 0) {
      setInitialScrollIndex(0)
    }
  }, [visibleStepIndex])

  useEffect(() => {
    if (initialScrollIndex > 0) {
      scrollToNext(false, initialScrollIndex)
      setVisibleStepIndex(initialScrollIndex)
    }
  }, [initialScrollIndex])

  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!Q2_1 || !Q2_2 || !Q2_3) {
      setInitialScrollIndex(0)
      console.log('1')
    } else if (!Q2_4 || !Q2_5 || !Q2_6) {
      setInitialScrollIndex(1)
      console.log('2')
    } else if (!Q2_7 || !Q2_8 || !Q2_9) {
      setInitialScrollIndex(2)
      console.log('3')
    } else {
      setInitialScrollIndex(3)
      console.log('4')
    }
  }, [containerRef])

  const scrollToNext = (isGoBack: boolean, times: number = 0) => {
    const container = containerRef.current

    if (container === null) {
      return
    }

    const firstChild = container.children[0] as HTMLElement
    let elementWidth = firstChild.offsetWidth

    if (isGoBack) {
      elementWidth = -elementWidth
    }

    container.scrollBy({ left: elementWidth * (times === 0 ? 1 : times), behavior: 'smooth' })
  }

  const questionSteps = [
    [
      { id: 'Q2_1', value: Q2_1, question: 'During professional disagreements, I tend to insist on my professional view', event: handleFirstQuestion },
      { id: 'Q2_2', value: Q2_2, question: "When I don't get credit for a professional achievement I feel deeply frustrated", event: handleSecondQuestion },
      { id: 'Q2_3', value: Q2_3, question: 'I excel at setting goals and crushing them', event: handleThirdQuestion }
    ],
    [
      { id: 'Q2_4', value: Q2_4, question: 'I can spend hours learning something new', event: handleFourthQuestion },
      { id: 'Q2_5', value: Q2_5, question: 'I like to have an in-depth view on things', event: handleFifthQuestion },
      { id: 'Q2_6', value: Q2_6, question: 'I highly enjoy solving riddles', event: handleSixthQuestion }
    ],
    [
      { id: 'Q2_7', value: Q2_7, question: "I genuinely like talking with people, even people I don't know", event: handleSeventhQuestion },
      { id: 'Q2_8', value: Q2_8, question: 'I care a lot about what other people think about me', event: handleEighthQuestion },
      { id: 'Q2_9', value: Q2_9, question: 'I have at least 4 close friends I regularly share intimate details with', event: handleNinthQuestion }
    ],
    [
      { id: 'Q2_10', value: Q2_10, question: "I believe I'm in charge of my own destiny", event: handleTenthQuestion },
      { id: 'Q2_11', value: Q2_11, question: 'I seek or aspire to managerial roles', event: handleEleventhQuestion },
      { id: 'Q2_12', value: Q2_12, question: 'I usually find myself instructing others how to do things', event: handleTwelfthQuestion }
    ]
  ]

  return (
    <>
      <BackButton className={'quiz_back_button absolute top-2 -left-2'} handleBackPage={handlePrevPage} backPage={''} />
      <ProgressBar value={visibleStepIndex + 1} className='w-full md:w-1/2 mx-auto mt-5'/>

      <p className='main_header_home text-lg md:text-xl md:mb-4 mt-4 text-center'>To which extent do the following statements describe you?</p>
      <form ref={formRef} id="quizForm" className={className} onSubmit={(event) => {
        event.preventDefault()
      }}>
        <div ref={containerRef} className='relative items-center flex justify-start overflow-hidden !w-full md:!w-[640px] bordered-question mt-1'>
          {questionSteps.map((questions, index) => (
            <div key={`step-${index}`} className={'min-w-full transition-all'}>
              {questions.map((question) => (
                // eslint-disable-next-line react/prop-types
                <QuestionBox key={`question-${index}-${question.id}`} id={question.id} question={question.question} handleSelection={question.event}/>
              ))}
            </div>
          ))}
        </div>
        <div className={'flex justify-center w-1/2'}>
          <button
            className={'quiz_form_button text-white rounded-lg px-9 py-2.5 text-center !mt-1 mb-5 h-16'}
            onClick={handleNextStep}>
            Next
          </button>
        </div>
      </form>
    </>
  )
}
