/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable */

import {useAppDispatch, useAppSelector} from '../../../app/hooks'
import {setCurrentUser} from '../../../app/quiz/quizSlice'
import {users} from "../../../app/services/Api";
import {toast} from "react-toastify";
import {setCredits} from "../../../app/quiz/userProfile";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export default function BrandaResultForm () {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const email = useAppSelector(state => state.quizReducer.email)
  const verificationCode = useAppSelector(state => state.quizReducer.verificationCode)
  const branda = useAppSelector(state => state.quizReducer.Branda)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    }
  }

  const handleSignIn = async () => {
    if (!currentUser) {
      await users.signIn(email, verificationCode, true, branda).then((response) => {
        if (response.jwt) {
          dispatch(setCurrentUser(response.user))
          toast.success('You have been successfully registered.')

          void users.getRemainingCredits().then((response) => {
            dispatch(setCredits(response.credits))
          })
        } else {
          toast.error('Incorrect email or password!')
        }
      })
        .catch(err => {
          toast.error(err)
          console.log(err)
        })
    }
    toast.success('You have been successfully registered.')
    clearLocalStorage()
    await users.updateRegisteredUser(currentUser?.id ?? 0, { step_name: 'email', has_finished_steps: true, has_finished_quiz: true }, true).finally(() => {
      users.getCurrentUser(true).then((response) => {
        if (!response.response) {
          dispatch(setCurrentUser(response))
        } else {
          dispatch(setCurrentUser(null))
        }
      })
        .catch((err) => {
          console.log(err)
        })
      navigate('/content-generator')
    }).finally(() => {
      void users.getRemainingCredits().then((response) => {
        dispatch(setCredits(response.credits))
      })
    })
  }

  useEffect(() => {
    setIsLoading(currentUser?.is_branda_email_sent === false)
  }, [currentUser])

  return (
    <LoadingButton loading={isLoading} onClick={handleSignIn} className={`${isLoading ? 'opacity-90' : ''} !capitalize !p-4 !w-44 !h-14 !bg-white !rounded-lg !text-base !font-medium !text-center !text-black mt-5`}>
      {isLoading ? '' : 'Build my Brand!'}
    </LoadingButton>
  )
}
