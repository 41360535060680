import Header from '../../atoms/Heading/Heading'
import { ReactComponent as Image } from '../../../img/quiz-business-about-page-img.svg'

interface Props {
  className: string
}

export default function BusinessAboutPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <Image className='w-40 md:w-52'/>
      <Header className='text-xl md:text-2xl font-medium md:mt-6 my-2' text="What Do You Own?"/>
    </div>
  )
}
