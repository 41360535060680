import React from 'react'
import BusinessAboutPageInfo from '../../molecules/BusinessAboutPage/BusinessAboutPageInfo'
import BusinessAboutPageForm from '../../molecules/BusinessAboutPage/BusinessAboutPageForm'
import Steps from '../../molecules/Steps/Steps'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
}

export default function BusinessAboutPage ({ className, currentPage, handleShowPage }: Props) {
  return (
    <div className={className}>
      <Steps currentPage={currentPage} />
        <ProgressBar value={3} isRegistration={true} className='w-full md:w-1/2 mx-auto my-2'/>
      <BusinessAboutPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-2 md:mt-6'/>
      <BusinessAboutPageForm handleShowPage={handleShowPage}
                             className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
