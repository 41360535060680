/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { PAGE, setGender } from '../../../app/quiz/quizSlice'
import { users } from 'app/services/Api'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizGenderPageForm ({ className, handleShowPage }: Props) {
  const dispatch = useAppDispatch()
  const gender = useAppSelector(state => state.quizReducer.Gender)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [selectedGender, setSelectedGender] = useState<string | null>(null)
  const [isDisabled, setIsDisabled] = useState(false)

  useEffect(() => {
    setIsDisabled(gender === '')
    setSelectedGender(gender)
  }, [gender])

  useEffect(() => {
    if (currentUser?.gender !== null && currentUser?.gender !== undefined && gender === '') {
      dispatch(setGender(currentUser?.gender))
      setSelectedGender(currentUser?.gender)
    }
  }, [])

  const handleOptionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (currentUser === null) {
      return
    }

    setSelectedGender(event.target.value)
    dispatch(setGender(event.target.value))

    await users.updateRegisteredUser(currentUser.id, {
      gender: event.target.value
    }, true)
  }

  const handleUpdateUser = async (e: React.FormEvent) => {
    e.preventDefault()

    await users.updateRegisteredUser(currentUser?.id ?? 0, {
      selectedGender,
      step_name: 'BusinessType'
    }, true)

    handleShowPage(PAGE.BUSINESS_TYPE)
  }

  return (
    <div className={className}>
    <FormControl className='relative w-auto md:w-full lg:w-[700px]'>
      <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        className='radio-group-page-six justify-center items-center gap-2.5'
      >
        <FormControlLabel
          value="Female"
          control={<Radio onChange={handleOptionChange} />}
          checked={selectedGender === 'Female'}
          label="Female"
          className="
            border border-gray-300 rounded-lg p-2 text-base w-44 !mx-0
            md:w-180
            md:m-0
            pl-8 md:pl-2
          "
        />
        <FormControlLabel
          value="Male"
          control={<Radio onChange={handleOptionChange} />}
          checked={selectedGender === 'Male'}
          label="Male"
          className="
            border border-gray-300 rounded-lg p-2 text-base w-44 !mx-0
            md:w-180
            md:m-0
            pl-8 md:pl-2
          "
        />
        <FormControlLabel
          value="Other"
          control={<Radio onChange={handleOptionChange} />}
          checked={selectedGender === 'Other'}
          label="Other"
          className="
            border border-gray-300 rounded-lg p-2 text-base w-44 !mx-0
            md:w-180
            md:m-0
            pl-8 md:pl-2
          "
        />
      </RadioGroup>
    </FormControl>
      <button
        disabled={isDisabled}
        onClick={handleUpdateUser}
        className='quiz_form_button text-white rounded-lg px-9 py-2.5 text-center relative !mt-8 md:h-16'
      >Next</button>
      </div>
  )
}
