/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import Input from '../../atoms/Input/Input'
import CustomLink from '../../atoms/Link/Link'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import { jwtDecode } from 'jwt-decode'
import { setCredits } from '../../../app/quiz/userProfile'
import VerificationInput from 'react-verification-input'
import LoadingButton from '@mui/lab/LoadingButton'
import CircularProgress from '@mui/material/CircularProgress'

interface jwtPayload {
  user_id: number
  target: string
  needs_sign_in: boolean
}

export default function SignInForm () {
  const [email, setEmail] = useState('')
  const [code, setCode] = useState<string>('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isVerificationSent, setIsVerificationSent] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)

  const queryParams = new URLSearchParams(location.search)
  const redirectQueryString = queryParams.get('redirect')

  useEffect(() => {
    setIsDisabled((email === '' && !isVerificationSent) || (code.length !== 6 && isVerificationSent))
    redirectIfLoggedIn()
  }, [email, code, currentUser, isVerificationSent])

  const redirectIfLoggedIn = () => {
    const redirectToken = redirectQueryString ? jwtDecode<jwtPayload>(redirectQueryString ?? '') : null

    if (redirectToken && !redirectToken.needs_sign_in) {
      navigate(redirectToken.target)
      return
    }

    if (currentUser) {
      if (redirectToken && currentUser.id !== redirectToken.user_id) {
        dispatch(setCurrentUser(null))
        return
      }

      if (redirectToken) {
        navigate(redirectToken.target)
      } else if (currentUser.remind_me_later_sent) {
        navigate('/quiz?create-subscription=1&user=' + currentUser.id)
      } else if (!currentUser.has_finished_steps) {
        navigate('/quiz')
      } else {
        navigate('/content-generator')
      }
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const clearLocalStorage = () => {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.clear()
    }
  }

  const handleSignIn = () => {
    void users.signIn(email, code, true).then((response) => {
      if (response.jwt) {
        clearLocalStorage()
        dispatch(setCurrentUser(response.user))
        toast.success('You have successfully logged in.')

        void users.getRemainingCredits().then((response) => {
          dispatch(setCredits(response.credits))
        })
      } else {
        setCode('')
        toast.error('Incorrect code')
      }
    })
      .catch(err => {
        if (err.response?.data) {
          setCode('')
          toast.error(err.response.data.detail)
        }
      })
  }

  const handleSendVerification = () => {
    setIsSending(true)
    void users.sendSsoCode(email).then(() => {
      setIsVerificationSent(true)
      toast.success('We\'ve sent a code in you email')
    }).catch(() => {
      toast.error('A user with this email doesn\'t exist')
    }).finally(() => {
      setIsSending(false)
    })
  }

  useEffect(() => {
    if (isVerificationSent) {
      setCode('')
    }
  }, [isVerificationSent])

  const handleButtonClick = () => {
    if (!isVerificationSent) {
      handleSendVerification()
    } else {
      handleSignIn()
    }
  }

  return (
    <div className='sign_in_sign_up visible_1 min-h-screen'>
      <div className="flex flex-col items-center justify-center">
        <div className='flex flex-row w-full justify-between px-8'>
          <CustomLink className='my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 mt-5' linkTo='/' name='myBranda' tm={true} />
        </div>
        <div className="flex flex-col justify-center items-center ">
          <div className="font-medium text-xl md:text-3xl leading-8 text-gray-900 mt-24">
            <h1>Welcome back to myBranda</h1>
          </div>
          <div className="font-normal font-weight-400 text-sm md:text-base text-gray-600 block md:mt-2">
            <h2>Sign in with your myBranda® email</h2>
          </div>
          <div className='flex flex-col gap-3 mt-7 w-full'>
            {!isVerificationSent && <Input
                onSubmit={handleButtonClick}
                placeholder="E-mail" type="email"
                onChange={handleEmailChange}
                className="px-2 py-4 h-13 border border-gray-300 rounded-xl bg-white blockw-96 focus:outline-none font-medium text-base text-gray-900"
                value={email}
            />}

            {isVerificationSent && <VerificationInput
                value={code}
                onChange={(value) => {
                  setCode(value)
                }}
                autoFocus={true}
                validChars="0-9"
                inputProps={{ inputMode: 'numeric' }}
                placeholder=''
                classNames={{
                  container: 'container',
                  character: 'character !bg-white !w-1/6',
                  characterInactive: 'character--inactive',
                  characterSelected: 'character--selected'
                }}
            />
            }

            <div className='flex justify-center'>
            <LoadingButton
              loading={isSending}
              loadingIndicator={<CircularProgress color="inherit" size={28} />}
              disabled={isDisabled} onClick={handleButtonClick}
              className={`quiz_form_button ${isVerificationSent ? '' : '!w-full'} !text-white !font-medium !rounded-lg px-5 py-2.5 text-center mr-2 h-14`}>
              {isVerificationSent ? 'Sign in' : 'Verify'}
            </LoadingButton>
            </div>

            {isVerificationSent &&
                <span onClick={handleSendVerification}
                      className="text-purple-700 cursor-pointer font-semibold flex justify-center mt-3 items-center text-sm hover:underline">
              Didn&apos;t receive the code? Send again
            </span>}
          </div>
        </div>
        <div className='flex flex-col justify-center items-center fixed bottom-1 left-0 right-0 text-sm'>
          <a href='/quiz'
             className="text-purple-700 cursor-pointer font-semibold flex justify-center mt-2 items-center text-sm hover:underline mb-5">
            Don&apos;t have an account? Sign up here!
          </a>
          <p className='flex flex-col md:flex-row items-center gap-1'>By signing up, you agree to our
            <a href="https://www.mybranda.com/privacy"
               className="text-purple-700 font-medium flex justify-end text-sm hover:underline">
              Privacy Policy and Terms of Service
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
