import React, { type ReactElement, useEffect, useState } from 'react'
import { Facebook, Instagram, LinkedIn, AutoAwesome } from '@mui/icons-material/'
import { FaTiktok } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { tasks, users } from '../../../app/services/Api'
import { toast } from 'react-toastify'
import {
  type MainCategory, setIsFirstTimeViewer,
  setIsFromBrandaSuggestion,
  setSelectedContentHistory,
  setSelectedTopic,
  type Topic
} from '../../../app/quiz/ContentGeneratorSlice'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import PremiumTopicBox from '../../molecules/ContentGenerator/PremiumTopicBox'
import Confetti from '../../molecules/Confetti/Confetti'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import { PopoverTooltip } from '../../molecules/PopOver/PopoverTooltip'

interface Platform {
  key: string
  name: string
  icon: ReactElement
  borderColor: string
  isDisabled: boolean
}

interface ViralTopic {
  id?: number
  title: string | null
}

interface ViralTopicBoxProps {
  itemCount: number
}

export default function PremiumMainPage () {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [randomTopicPoolIds, setRandomTopicPoolIds] = useState<number[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isConfettiVisible, setIsConfettiVisible] = useState(false)
  const [isViralTopicsLoading, setIsViralTopicsLoading] = useState(false)
  const isMobile = window.innerWidth < 768
  const [mainCategories, setMainCategories] = useState<MainCategory[]>([])

  const [viralTopics, setViralTopics] = useState<ViralTopic[]>([])

  const platforms: Platform[] = [
    {
      key: 'Facebook',
      name: 'Facebook',
      icon: <Facebook fontSize={'large'} sx={{
        color: 'blue'
      }}/>,
      borderColor: 'blue',
      isDisabled: false
    }, {
      key: 'Instagram',
      name: 'Instagram',
      icon: <Instagram fontSize={'large'} sx={{
        color: '#C13584'
      }}/>,
      borderColor: '#C13584',
      isDisabled: false
    }, {
      key: 'Linkedin',
      name: 'LinkedIn',
      icon: <LinkedIn fontSize={'large'} sx={{
        color: '#0077B5'
      }}/>,
      borderColor: '#0077B5',
      isDisabled: false
    }, {
      key: 'Tiktok',
      name: 'TikTok',
      icon: <FaTiktok size={'28px'}/>,
      borderColor: 'black',
      isDisabled: true
    }
  ]

  const [topics, setTopics] = useState<Record<number, Topic[]>>([])
  const [rawTopics, setRawTopics] = useState<Topic[]>([])

  const fetchTopics = async () => {
    if (currentUser === null || isViralTopicsLoading) {
      return
    }

    await tasks.generateTopics(currentUser.id)
      .then(response => {
        const topics: string[] = response.message.split('\n\n---\n')
        topics.filter(topic => topic !== ' ')
          .forEach((topic: string) => {
            const lines = topic.split('\n')
            const title = lines[0].replaceAll('*', '').replaceAll('#', '')
            const content = lines.slice(1).join('\n')
            updateViralTopics(title, content)
          })

        setIsViralTopicsLoading(false)
      })
  }

  const mapTopics = (topics: Topic[]) => {
    return topics.reduce((acc: Record<number, Topic[]>, item) => {
      if (item.main_category === null) {
        return acc
      }

      const categoryId = item.main_category.id

      if (acc[categoryId] === undefined) {
        acc[categoryId] = []
      }

      acc[categoryId].push(item)
      return acc
    }, {})
  }

  const updateViralTopics = (title: string, content: string) => {
    setViralTopics(current => [...current, { id: 0, title, content }])
  }

  useEffect(() => {
    if (currentUser === null) {
      return
    }

    setIsLoading(true)

    void tasks.getMainCategories(currentUser).then((response: MainCategory[]) => {
      setMainCategories(response)
    }).then(() => {
      void tasks.getTopics(currentUser).then((response) => {
        setRawTopics(response)
        setTopics(mapTopics(response))
        setRandomTopicPoolIds(response.filter((topic: Topic) => {
          return topic.include_in_suggestion
        }).map((topic: Topic) => topic.id))
      }).catch(() => {
        toast.error('Something went wrong fetching the topics. Please try again.')
      }).finally(() => {
        setIsLoading(false)
      })
    })

    dispatch(setSelectedTopic(null))

    setIsViralTopicsLoading(true)
    const getViralTopics = async () => {
      await tasks.getViralTopics(currentUser.id).then((response) => {
        if (response.length === 0) {
          setIsViralTopicsLoading(true)
          void fetchTopics()
        } else {
          const topics = response.map((topic: ViralTopic) => {
            return {
              id: topic.id,
              title: topic.title
            }
          })

          setIsViralTopicsLoading(false)
          setViralTopics(topics)
        }
      })
    }

    getViralTopics().catch((error) => {
      console.error(error)
    })

    if (!currentUser.has_viewed_welcome_video) {
      setIsConfettiVisible(true)
      setTimeout(() => {
        setIsConfettiVisible(false)
      }, 3000)

      void users.updateUser(currentUser.id, {
        has_viewed_welcome_video: true
      }).then(() => {
        dispatch(setCurrentUser({
          ...currentUser,
          has_viewed_welcome_video: true
        }))
      })
    }
  }, [currentUser])

  const handleRandomTopicSelection = () => {
    if (randomTopicPoolIds.length === 0) {
      return
    }

    dispatch(setIsFirstTimeViewer(currentUser?.has_viewed_content_generator === false))

    const randomTopicId: number = randomTopicPoolIds[Math.floor(Math.random() * randomTopicPoolIds.length)]
    const randomTopic: Topic | undefined = rawTopics.find((topic: Topic) => topic.id === randomTopicId)

    if (randomTopic !== undefined) {
      handleTopicSelection(randomTopic, true)
    }
  }

  const handleTopicSelection = (topic: Topic, isSuggestion: boolean = false, taskId?: number) => {
    dispatch(setSelectedContentHistory(null))
    dispatch(setIsFromBrandaSuggestion(isSuggestion))
    dispatch(setSelectedTopic(topic))

    if (!isSuggestion) {
      dispatch(setIsFirstTimeViewer(
        !randomTopicPoolIds.includes(topic.id) && currentUser?.has_viewed_content_generator === false
      ))
    }

    navigate('/content-generator/' + topic.id + (taskId !== undefined ? `?viral-task-id=${taskId}` : ''))
  }

  const ViralTopicBox = ({ id, title }: ViralTopic) => {
    return (
            <div
                onClick={() => {
                  handleViralTopicClick(id)
                }}
                key={title}
                className={'cursor-pointer h-28 w-[45%] md:w-48 inline-flex justify-center items-center text-center bg-[#f4ebc2] text-black p-1 md:p-3 border rounded-xl'}>
                {title === null && <CircularProgress color='inherit'/>} {title}
            </div>
    )
  }

  const handleViralTopicClick = (id?: number) => {
    if (currentUser === null || id === undefined) {
      return
    }

    if (!currentUser.has_viewed_content_generator) {
      handleRandomTopicSelection()
      return
    }

    void tasks.getTopics(currentUser, 'More Topics').then((response: Topic[]) => {
      handleTopicSelection(response[0], false, id)
    })
  }

  const ViralTopicGroup = ({ itemCount }: ViralTopicBoxProps) => {
    if (isViralTopicsLoading) {
      const items = []

      for (let i = 0; i < itemCount; i++) {
        items.push(<ViralTopicBox key={i} title={null}/>)
      }

      return (
          <>{items}</>
      )
    }

    return (
        <>
      {viralTopics.slice(0, 3).map((topic) => (
          <ViralTopicBox key={topic.title} id={topic.id} title={topic.title} />
      ))}
        </>
    )
  }

  const getLength = (index: number) => {
    return Object.values(topics).reduce((acc, topics, i) => {
      return i < index ? acc + topics.length : acc
    }, 0)
  }

  return (
        <div>
            <Confetti visible={isConfettiVisible}/>

            <Backdrop
                sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                open={isLoading}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>

            <div className={isMobile ? 'premium-with-image' : 'premium-with-image-web'}>
                <p className={`${isMobile ? '' : 'mt-10'} font-bold main_header_home text-2xl md:text-4xl leading-7 text-black p-0 pb-1 text-center`}>
                    <div className='main_header_home inline-block'>
                      Let&apos;s Post Something<span className='hidden md:inline'> Together</span>!
                    </div>
                </p>
                <p className={'text-lg text-center'}>
                    <span className='hidden md:inline-block'>The Real</span> Magic happens on <span className='hidden md:inline-block'>on your</span> <b>personal</b> accounts
                </p>
            </div>

            <div className='p-5 md:px-16 md:pt-10'>
                <p className='text-center text-xl'>Available platforms</p>
                <div className='md:flex md:justify-center'>
                    <div className='flex flex-row items-center justify-between mt-4'>
                        {platforms.map((platform) => (
                            <div key={platform.key} className='relative overflow-visible w-1/4'>
                                <div
                                    className={
                                        `${platform.isDisabled ? 'opacity-40 hover:opacity-100' : ''} flex flex-col justify-center items-center md:pr-3`
                                    }>
                                    {platform.icon}
                                    {platform.isDisabled &&
                                        <span className='banner left-3 md:left-[-11px] rounded inline-block mt-2'>Coming Soon</span>}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {mainCategories.map((category, categoryIndex) => (
                    <div key={category.title} className={'mt-10 md:mt-10 bg-white rounded-3xl relative border shadow-md py-6 px-5 md:py-6 md:px-6'}>
                        <p className={'text-lg md:text-3xl mt-2 md:mt-0 text-center'}>
                          <span className='main_header_home'>{category.title}</span>
                          <span className='absolute md:right-4 right-3 top-2 text-sm'>
                            <PopoverTooltip className={'opacity-50 inline-block'} tooltip={category.description} />
                          </span>
                        </p>
                        <div className='flex flex-wrap justify-center mt-6 gap-3'>
                          {categoryIndex === 0 && <ViralTopicGroup itemCount={isViralTopicsLoading ? 4 : 3} />}
                           {topics[category.id]?.map((topic, index) => (
                               <PremiumTopicBox
                                   clickEvent={() => {
                                     if (categoryIndex === 0 && currentUser?.has_viewed_content_generator === false) {
                                       handleRandomTopicSelection()
                                     } else {
                                       handleTopicSelection(topic)
                                     }
                                   }}
                                   className={categoryIndex === 0 && isViralTopicsLoading ? 'hidden' : ''}
                                   key={topic.name}
                                   index={index + getLength(categoryIndex)} topic={topic}/>
                           ))}
                          {categoryIndex === 2 && (
                              <div
                                  onClick={handleRandomTopicSelection}
                                  key={'random'}
                                  className={'cursor-pointer suggest-button bg-center bg-cover inline-flex justify-center items-center text-center text-white p-3 border rounded-lg h-28 w-[45%] md:w-48'}>
                                <span className='z-10'>Suggest <AutoAwesome fontSize={'small'}/></span>
                              </div>
                          )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
  )
}
