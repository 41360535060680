import React, { useEffect, useState } from 'react'
import BackButton from 'components/atoms/BackButton/BackButton'
import { useNavigate } from 'react-router-dom'
import GenerationHistory from '../../organisms/ContentGenerator/GenerationHistory'
import Content from '../../organisms/ContentGenerator/Content'
import Box from '@mui/material/Box'
import { Tab, Tabs } from '@mui/material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import AskBranda from '../../atoms/Icon/AskBranda'
import {
  setHistoryItemClicked,
  setIsGuideRunning,
  setSelectedContentHistory,
  setSelectedTopic
} from '../../../app/quiz/ContentGeneratorSlice'

export default function ContentGenerator () {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isHistoryItemClicked = useAppSelector(state => state.contentGeneratorSlice.isHistoryItemClicked)
  const isHistoryHidden = useAppSelector(state => state.contentGeneratorSlice.isHistoryHidden)
  const [value, setValue] = useState<number>(1)
  const isMobile = window.innerWidth <= 768

  useEffect(() => {
    if (isHistoryItemClicked) {
      setValue(1)
      dispatch(setHistoryItemClicked(false))
    }
  }, [isHistoryItemClicked])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(setSelectedContentHistory(null))
    setValue(newValue)
  }

  const handleBackPage = () => {
    dispatch(setSelectedTopic(null))
    navigate('/content-generator/')
    window.scrollTo(0, 0)
  }

  function a11yProps (index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  return (
    <div className={'relative flex flex-col gap-3 justify-center w-full md:basis-9/12 p-5 md:p-16 mb-24 bg-white rounded-3xl'}>
      <AskBranda handleModalOpen={() => {
        dispatch(setIsGuideRunning(true))
      }}/>

      <BackButton
        backPage={''}
        handleBackPage={() => {
          handleBackPage()
        }}
        className="quiz_back_button block"
      />
      {isMobile
        ? <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='mb-4'>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="History" {...a11yProps(0)} className={`w-1/2 history ${isHistoryHidden ? '!hidden' : ''}`} />
              <Tab label="Generator" {...a11yProps(1)} className={'w-1/2'}/>
            </Tabs>
          </Box>
            {!isHistoryHidden && <GenerationHistory className={value === 0 ? '' : 'hidden'}/>}
          <Content className={value === 1 ? '' : 'hidden'}/>
        </Box>
        : <div className={'flex'}>
            {!isHistoryHidden && (<div className={'w-1/3'}>
              <GenerationHistory/>
            </div>)}
          <div className={isHistoryHidden ? 'w-full' : 'w-2/3'}>
            <Content className='pl-10'/>
          </div>
        </div>
      }
    </div>
  )
}
