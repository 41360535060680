import React, { useEffect, useState } from 'react'
import { CheckCircleRounded } from '@mui/icons-material'
import Confetti from '../Confetti/Confetti'
import { PAGE } from '../../../app/quiz/quizSlice'

interface Props {
  currentPage: string
}

export default function Steps ({ currentPage }: Props) {
  const [stepNumber, setStepNumber] = useState<number>(1)
  const [isConfettiVisible, setIsConfettiVisible] = useState(false)

  const allSteps = [
    'Sign Up',
    'Set-up',
    'Quiz'
  ]

  const stepPages = [
    [PAGE.EMAIL, PAGE.VERIFICATION_CODE],
    [PAGE.GOALS, PAGE.EMPLOYEE_PROFESSION, PAGE.EMPLOYEE_KEYWORDS, PAGE.EMPLOYEE_STATUS, PAGE.EMPLOYEE_EMPLOYER, PAGE.EMPLOYEE_PROFESSION, PAGE.GENDER, PAGE.BUSINESS_TYPE, PAGE.PROFESSION, PAGE.SOLO_KEYWORDS, PAGE.BUSINESS_ABOUT, PAGE.BUSINESS_MORE_INFO, PAGE.BUSINESS_KEYWORDS],
    [PAGE.QUIZ_FIRST_STEP, PAGE.QUIZ_SECOND_STEP, PAGE.QUIZ_GENIE_STEP, PAGE.QUIZ_FINAL_STEP]
  ]

  useEffect(() => {
    if ([stepPages[0][0], stepPages[1][0], stepPages[2][0]].includes(currentPage)) {
      displayConfetti()
    }

    if (stepPages[0].includes(currentPage)) {
      setStepNumber(1)
    } else if (stepPages[1].includes(currentPage)) {
      setStepNumber(2)
    } else if (stepPages[2].includes(currentPage)) {
      setStepNumber(3)
    }
  }, [currentPage])

  const displayConfetti = () => {
    setIsConfettiVisible(true)
    setTimeout(() => {
      setIsConfettiVisible(false)
    }, 3000)
  }

  return (
    <>
      <Confetti visible={isConfettiVisible}/>
      <div
        className='quiz_steps mt-3 mb-2 md:mt-10 text-xs md:text-base md:-translate-x-4 flex flex-row justify-between items-center md:ml-auto md:mr-auto w-full md:w-1/3'>

        {allSteps.map((stepName, index) => (
          <div
            key={'step-' + index}
            className={`quiz_steps_step flex flex-row justify-center items-center ${stepNumber === (index + 1) ? 'active' : ''} ${stepNumber > (index + 1) ? 'done' : ''}`}>
            <h6>
              {stepName}
            </h6>
            {
              stepNumber > (index + 1)
                ? <CheckCircleRounded className='text-[#552BB1] ml-1' fontSize='small'/>
                : <span
                  className='rounded-full p-0 text-[10px] md:!text-[13px] border border-black inline-flex justify-center items-center h-[17px] w-[17px] ml-1'>{index + 1}</span>
            }
          </div>
        ))
        }
      </div>
    </>
  )
}
