import { useEffect, useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import { useAppSelector } from '../../../app/hooks'

interface Props {
  className: string
}

export default function QuizThirdStepTitleTwo ({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const stateSection = useAppSelector(state => state.quizReducer.section)
  const [section, setSection] = useState<string | null>(null)

  useEffect(() => {
    if (stateSection !== '') {
      setSection(stateSection)
    } else if (currentUser?.quiz_step?.section !== undefined) {
      setSection(currentUser.quiz_step.section)
    }
  }, [currentUser])

  let content = ''
  switch (section) {
    case 'E':
      content = "My <span class='md:font-bold'>goal-crushing</span> superpower is definitely"
      break
    case 'I':
      content = "I am at my <span class='md:font-bold'>best</span> when"
      break
    case 'S':
      content = "At work, I will <span class='md:font-bold'>always</span>"
      break
    case 'C':
      content = "At work, I will <span class='md:font-bold'>always</span>"
      break
    default:
      content = ''
      break
  }

  return (
    <div className={className}>
      <Header className='text-md md:text-base font-bold md:font-medium mb-2 md:mb-4 text-center' text={content}/>
    </div>
  )
}
