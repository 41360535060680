import React, { useEffect, useRef, useState } from 'react'
import { TextField } from '@mui/material'

interface Props {
  isOpen: boolean
  isRTL?: boolean
  onButtonClick: ((reason: string | null) => void)
}

export default function RegenerateDropdown ({ isOpen, onButtonClick, isRTL }: Props) {
  const [note, setNote] = useState<string>('')
  const [isTextFieldShown, setIsTextFieldShown] = useState<boolean>(false)
  const element = useRef<HTMLDivElement>(null)
  const isMobile = window.innerWidth < 768

  useEffect(() => {
    setIsTextFieldShown(false)

    if (!isOpen) {
      setNote('')
    } else {
      element.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }, [isOpen])

  return (
    <div ref={element} className={`flex mt-3 gap-x-4 ${isOpen ? '' : 'hidden'}`}>
      <div className='w-full p-4 rounded-lg border border-purple-200 shadow-lg'>
        <p className='text-center text-lg mb-4'>What would you like to get?</p>
        <TextField
          size='small'
          placeholder='Write precisely what to change'
          className={`!w-full ${isTextFieldShown ? '' : '!hidden'} ${isRTL === true ? 'rtl' : ''}`}
          value={note}
          onChange={(e) => {
            setNote(e.target.value)
          }}/>

        <div className={`flex ${isTextFieldShown ? 'justify-center' : 'justify-evenly'} gap-x-3 mt-2`}>
          {isTextFieldShown && <button
            onClick={() => {
              onButtonClick(note)
            }}
            className="bg-[#8847BF] hover:bg-[#6e2fa3] text-white md:font-medium rounded-lg mt-2 text-base px-4 md:px-6 py-2.5 text-center">
            Regenerate
          </button>}

          {!isTextFieldShown && <button
            onClick={() => {
              onButtonClick(null)
            }}
            className={'bg-[#8847BF] hover:bg-[#6e2fa3] text-white w-1/2 md:font-medium rounded-lg text-sm md:text-base px-2 md:px-6 py-1.5 md:py-2.5 text-center'}>
            {isMobile ? 'Another version' : 'I want another version'}
          </button>}

          {!isTextFieldShown && <button
            onClick={() => {
              setIsTextFieldShown(true)
            }}
            className={'bg-white text-[#8847BF] hover:bg-[#f1e0ff] border-2 border-[#8847BF] w-1/2 md:font-medium rounded-lg text-sm md:text-base px-2 md:px-6 py-1.5 md:py-2.5 text-center'}>
            {isMobile ? 'A specific change' : 'Change something specific'}
          </button>}
        </div>
      </div>
    </div>
  )
}
