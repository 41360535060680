import React from 'react'
import ReactPlayer from 'react-player'
import { PlayArrow } from '@mui/icons-material'

interface Props {
  filename: string
}

export default function VideoPlayer ({ filename }: Props) {
  const [isPlaying, setIsPlaying] = React.useState<boolean>(false)
  const isMobile = window.innerWidth < 768

  return (
    <div className="w-full h-[60%] relative text-7xl">
      {!isPlaying && !isMobile && <PlayArrow fontSize='inherit' className={'!cursor-pointer border-4 shadow-lg border-white text-white rounded-full absolute left-[50%] top-[50%] -translate-y-2/3 -translate-x-1/2'} />}
        <ReactPlayer
          url={`https://d3nsqgxbbxtdc8.cloudfront.net/videos/${filename}`}
          onPause={() => {
            setIsPlaying(false)
          }} onPlay={() => {
            setIsPlaying(true)
          }}
          playing={isPlaying}
          playsinline={true}
          controls
          height='100%'
          width="100%" />
    </div>
  )
}
