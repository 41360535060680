import Header from '../../atoms/Heading/Heading'
import { ReactComponent as QuizPageOneImg } from '../../../img/gender-image.svg'

interface Props {
  className: string
}

export default function BusinessTypePageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizPageOneImg className={'w-44 md:w-52'}/>
      <Header className='text-xl md:text-2xl font-medium mt-1 md:mt-4 mb-2' text="What's Your Business?"/>
    </div>
  )
}
