import React from 'react'
import PremiumMainPage from './PremiumMainPage'

export default function TopicSelector () {
  return (
      <div className={'flex flex-col justify-center w-full md:basis-9/12 mb-24 bg-white rounded-3xl'}>
          <PremiumMainPage/>
      </div>
  )
}
