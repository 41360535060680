/* eslint-disable */
import {type ApiClient} from '../ApiClient'

export class Profession {
  private readonly api: ApiClient

  constructor(api: ApiClient) {
    this.api = api
  }

  async getProfession(professionId: number): Promise<any> {
    return await this.api.get<object>(`professions/${professionId}/`)
  }

  async getAllProfessions(userId: number): Promise<any> {
    return await this.api.get<object[]>(`professions/?user_id=${userId}`)
  }

  async getAllEmployeeProfessions(userId: number): Promise<any> {
    return await this.api.get<object[]>(`employee_professions/?user_id=${userId}`)
  }

  async createEmployeeProfession(data: object, credentials?: boolean) {
    return await this.api.post<object>('employee_professions/', data, credentials)
  }

  async getBusinessTypes(userId: number, name?: string): Promise<any> {
    const queryParam = name ? `&name=${name}` : '';
    return await this.api.get<object[]>(`business_types/?user_id=${userId}${queryParam}`)
  }

  async createBusinessType(data: object, credentials?: boolean) {
    return await this.api.post<object>('business_types/', data, credentials)
  }

  async create(data: object, credentials?: boolean) {
    return await this.api.post<object>('professions/', data, credentials)
  }

  async update(id: number, data: object, credentials?: boolean): Promise<any> {
    return await this.api.put<object>(`professions/${id}/`, data, credentials)
  }

  async delete(id: number, credentials?: boolean): Promise<any> {
    return await this.api.delete<object>(`professions/${id}/`, {id}, credentials)
  }


}
