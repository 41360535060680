import React from 'react'
import BusinessMoreInfoPageInfo from '../../molecules/BusinessMoreInfoPage/BusinessMoreInfoPageInfo'
import BusinessMoreInfoPageForm from '../../molecules/BusinessMoreInfoPage/BusinessMoreInfoPageForm'
import AskBranda from '../../atoms/Icon/AskBranda'
import Steps from '../../molecules/Steps/Steps'
import { ProgressBar } from '../../atoms/ProgressBar/ProgressBar'

interface Props {
  className: string
  currentPage: string
  handleShowPage: (page: string) => void
  handleModalOpen: () => void
}

export default function BusinessMoreInfoPage ({ className, currentPage, handleShowPage, handleModalOpen }: Props) {
  return (
    <div className={className}>
      <div className='flex justify-end w-full custom-quiz-margin pt-5 pr-5'>
        <AskBranda handleModalOpen={handleModalOpen}/>
      </div>
      <Steps currentPage={currentPage} />
        <ProgressBar value={4} isRegistration={true} className='w-full md:w-1/2 mx-auto my-2'/>
      <BusinessMoreInfoPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-1 md:mt-10'/>
      <BusinessMoreInfoPageForm handleShowPage={handleShowPage}
                                className='flex flex-col justify-center items-center w-full relative visible_1'/>
    </div>
  )
}
