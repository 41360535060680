import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizGenderPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='main_header_home text-3xl font-medium text-center mt-4 mb-5' text="I am a" />
    </div>
  )
}
