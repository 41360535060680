import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import React, { useEffect, useState } from 'react'
import Card from '../../atoms/Card/Card'
import { PAGE, setCurrentUser, UserType } from '../../../app/quiz/quizSlice'
import { users } from '../../../app/services/Api'
import { useNavigate } from 'react-router-dom'

interface Props {
  className: string
}

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

interface CardInterface {
  id: number
  type: string
  step_name: string
  next_page: string
  text: string
  tooltip: string
  isActive: boolean
}

export default function BusinessTypePageForm ({ className, handleShowPage }: Props) {
  const theme = useTheme()
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isDisabled, setIsDisabled] = useState(true)
  const [userType, setUserType] = useState<string | null>(null)
  const navigate = useNavigate()

  const [cards, setCards] = useState<CardInterface[]>([
    {
      id: 1,
      type: UserType.ENTREPRENEUR,
      step_name: 'Profession',
      next_page: PAGE.PROFESSION,
      text: 'I’m a Solo Entrepreneur',
      tooltip: 'People who work alone. Service Providers, Coaches, Consultants, Therapists, Freelancers etc.',
      isActive: false
    },
    {
      id: 2,
      type: UserType.BUSINESS,
      step_name: 'BusinessAbout',
      next_page: PAGE.BUSINESS_ABOUT,
      text: 'I’m a Business Owner',
      tooltip: 'People who own a brand company, employ other people, sell products, etc.',
      isActive: false
    },
    {
      id: 3,
      type: UserType.EMPLOYEE,
      step_name: 'Employee Profession',
      next_page: PAGE.EMPLOYEE_PROFESSION,
      text: 'I’m a Salaried Employee',
      tooltip: 'People who are currently employed by a company and on its payroll or seeking such a position.',
      isActive: false
    }
  ])

  const handleSelection = (card: CardInterface) => {
    if (currentUser === null) {
      return
    }

    cards.map((item) => {
      item.isActive = card.id === item.id
      return item
    })

    dispatch(setCurrentUser({
      ...currentUser,
      user_type: card.type
    }))

    setUserType(card.type)

    setIsDisabled(cards.filter((card) => card.isActive).length === 0)
    setCards([...cards])
  }

  const handleUpdateUser = (e: React.FormEvent) => {
    e.preventDefault()

    const card = cards.find((item) => {
      return item.isActive
    })

    if (card === undefined) {
      return
    }

    if (currentUser === null) {
      navigate('/sign-in')
      return
    }

    void users.updateRegisteredUser(currentUser.id, {
      user_type: userType,
      step_name: card?.step_name
    }, true).then(() => {
      dispatch(setCurrentUser({
        ...currentUser,
        user_type: userType
      }))
    })

    handleShowPage(card.next_page)
  }

  useEffect(() => {
    if (currentUser !== null) {
      const card = cards.find((item) => {
        return item.type === currentUser.user_type
      }) ?? null

      if (card !== null) {
        handleSelection(card)
      }
    }
  }, [])

  return (
    <div className={className}>
      <div
        className={`flex ${isSmallerScreen ? 'flex-col items-center' : 'flex-row'} justify-center w-full`}>
        {cards.map((card) => (
          <Card
            key={card.id}
            id={card.id}
            handleCardClick={() => {
              handleSelection(card)
            }}
            tooltip={card.tooltip}
            isActive={card.isActive}
            text={card.text}
            className={`${isSmallerScreen ? '!w-[70%]' : ''} business_selection_button !m-1 md:!m-2 border border-gray-300 text-black flex justify-center items-center relative md:!h-14 !h-10 !py-2.5`}
          />
        ))}
      </div>

      <button
        onClick={handleUpdateUser}
        disabled={isDisabled}
        className="quiz_form_button text-white rounded-lg px-9 py-2.5 text-center !mt-6"
      >
        Next
      </button>
    </div>
  )
}
