import React from 'react'
import QuizEmailPageInfo from '../../molecules/QuizEmailPageInfo/QuizEmailPageInfo'
import QuizEmailPageForm from '../../molecules/QuizEmailPageForm/QuizEmailPageForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void

}

export default function QuizEmailPage ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <QuizEmailPageInfo className='flex flex-col justify-center relative items-center gap-5 visible_1' />
      <QuizEmailPageForm handleShowPage={handleShowPage} className='flex pt-2 flex-col md:flex-row justify-center relative items-center visible_1 gap-2 mt-5' />
    </div>
  )
}
