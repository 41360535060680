import Header from '../../atoms/Heading/Heading'
import { ReactComponent as BusinessTypePageImg } from '../../../img/quiz-business-type-page-img.svg'

interface Props {
  className: string
}

export default function QuizProfessionPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <BusinessTypePageImg className={'w-44 md:w-52'} />
      <Header className='text-xl md:text-2xl font-medium mt-0 md:mt-4' text="Tell Us More About You!" />
    </div>
  )
}
