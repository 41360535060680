import React from 'react'
import Header from '../../atoms/Heading/Heading'

interface Props {
  className: string
}

export default function QuizPageThreeInfo ({ className }: Props) {
  return (
    <div className={className}>
      <Header className='main_header_home text-xl md:text-xl mt-4' text='My most dominant attribute is'/>
    </div>
  )
}
