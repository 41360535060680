import React from 'react'
import { HelpRounded } from '@mui/icons-material'
import { Popover } from '@mui/material'

interface Props {
  tooltip: string
  text?: string | null
  className?: string
  textClassName?: string
  iconSize?: 'inherit' | 'small' | 'medium' | 'large'
}

export function PopoverTooltip ({ tooltip, className, textClassName, text = null, iconSize = 'inherit' }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | HTMLSpanElement | null>(null)
  const isMobile = window.innerWidth <= 768

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement | HTMLSpanElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
      <div className={className}>
          {(text !== null && text !== undefined)
            ? <span className={textClassName} onClick={(e) => {
              handlePopoverOpen(e)
            }}>{text}</span>
            : (
              <HelpRounded fontSize={iconSize} onClick={(e) => {
                handlePopoverOpen(e)
              }} />
              )}
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            slotProps={{
              paper: {
                sx: {
                  borderRadius: '4px',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1) '
                }
              }
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
        >
            <div className='bg-white text-center text-gray-900 p-4 rounded text-sm border border-gray-300'>
                <p dangerouslySetInnerHTML={{ __html: isMobile ? tooltip : tooltip.replaceAll('\n', '<br>') }}></p>
            </div>
        </Popover>
      </div>
  )
}
