import TextField from '@mui/material/TextField'
import { Backdrop, FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from '@mui/material'
import { LANGUAGES } from '../../atoms/CountryDropdown/languages'
import React, { type ReactElement, useEffect, useRef, useState } from 'react'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import {
  Instagram,
  LinkedIn,
  FacebookRounded, AutoAwesome, Event
} from '@mui/icons-material'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { toast } from 'react-toastify'
import { tasks, users } from '../../../app/services/Api'
import { useNavigate, useParams } from 'react-router-dom'
import Navigator from '../../molecules/ContentGenerator/Navigator'
import TaskPlatformList from '../../molecules/ContentGenerator/TaskPlatformList'
import ButtonGroup from '../../molecules/ContentGenerator/ButtonGroup'
import {
  type ContentHistory, RegenerationType,
  setContentHistory, setIsGuideRunning, setIsHistoryHidden, setIsSingleTaskTopic,
  setSelectedContentHistory, setSelectedTopic, type Task, type Topic, type ViralTopic
} from '../../../app/quiz/ContentGeneratorSlice'
import { setCredits } from '../../../app/quiz/userProfile'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'
import EndTrialModal from '../../molecules/ProfileForm/Modals/EndTrialModal'
import Tooltip from '@mui/material/Tooltip'
import Joyride, { type CallBackProps, type Placement, STATUS } from 'react-joyride'
import { setAutoViewGuide, setCurrentUser } from '../../../app/quiz/quizSlice'
import RegenerateDropdown from '../../molecules/ProfileForm/Modals/RegenerateDropdown'
import { Rating } from '@mui/lab'
import ReactMarkdown from 'react-markdown'

interface TaskGroupItem {
  tasks?: Task[]
  isActive?: boolean
  isVisible?: boolean
}

interface Platform {
  key: string
  task_id: number | null
  icon: ReactElement
  isVisible: boolean
  url: string
}

interface Props {
  className?: string
}

type TaskGroup = Record<string, TaskGroupItem>

export default function Content ({ className }: Props) {
  const navigate = useNavigate()
  const [isTextFromRight, setIsTextFromRight] = useState('')
  const [selectedLanguage, setSelectedLanguage] = useState('American English dialect')
  const [isLoading, setIsLoading] = useState(false)
  const [generatedText, setGeneratedText] = useState('')
  const [citations, setCitations] = useState<string[] | null>(null)
  const [viralTopicText, setViralTopicText] = useState<string | null>(null)
  const [viralTopicTitle, setViralTopicTitle] = useState<string | null>(null)
  const [viralTopics, setViralTopics] = useState<ViralTopic[]>([])
  const [personalization, setPersonalization] = useState('')
  const [clusterIndex, setClusterIndex] = useState<number>(0)
  const [currentTask, setCurrentTask] = useState<Task | null>(null)
  const [selectedPlatformIndex, setSelectedPlatformIndex] = useState<number | null>(null)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const autoViewGuide = useAppSelector(state => state.quizReducer.autoViewGuide)
  const selectedHistoryItem = useAppSelector(state => state.contentGeneratorSlice.selectedContentHistory)
  const historyItems = useAppSelector(state => state.contentGeneratorSlice.contentHistories)
  const isGuideManuallyRunning = useAppSelector(state => state.contentGeneratorSlice.isGuideRunning)
  const remainingCredits = useAppSelector(state => state.user.credits)
  const isFirstTime = useAppSelector(state => state.contentGeneratorSlice.isFirstTime)
  const [taskList, setTaskList] = useState<Task[]>([])
  const [taskGroupList, setTaskGroupList] = useState<TaskGroup>({})
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [hasPlatformSelectionError, setHasPlatformSelectionError] = useState(false)
  const [hasPersonalizationError, setHasPersonalizationError] = useState(false)
  const [isFromPlatformSelection, setIsFromPlatformSelection] = useState(false)
  const [isGenerated, setIsGenerated] = useState(false)
  const [isAfterGeneration, setIsAfterGeneration] = useState(false)
  const [isPersonalizationExpanded, setIsPersonalizationExpanded] = useState(false)
  const [isPersonalizationFocused, setIsPersonalizationFocused] = useState(false)
  const [isCalendarLoading, setIsCalendarLoading] = useState<boolean>(false)
  const [isFromHistoryUpdate, setIsFromHistoryUpdate] = useState<boolean>(false)
  const [isRated, setIsRated] = useState<boolean>(false)
  const [ratingValue, setRatingValue] = useState<number | null>(null)
  const inputRef = useRef(null)
  const { id } = useParams()
  const platformList = ['Facebook', 'Instagram', 'Linkedin']
  const viralTopicSubtitle = 'Add your personal perspective and unique angle on this topic and make it your own 💪🏼'
  const dispatch = useAppDispatch()
  const [runGuide, setRunGuide] = useState<boolean>(false)
  const [isRegenerateDropdownShown, setIsRegenerateDropdownShown] = useState<boolean>(false)
  const isMobile = window.innerWidth <= 768
  const query = new URLSearchParams(location.search)
  const topic = useAppSelector((state) => state.contentGeneratorSlice.topic)
  const [viralTaskId, setViralTaskId] = useState<string | null>(null)
  const [isViralTopicListVisible, setIsViralTopicListVisible] = useState<boolean>(false)

  const personalizationRef = useRef<HTMLDivElement | null>(null)

  const [platforms, setPlatforms] = useState<Platform[]>([
    {
      key: 'Facebook',
      icon: <FacebookRounded fontSize={'large'} sx={{
        color: 'blue'
      }}/>,
      isVisible: true,
      task_id: null,
      url: 'https://www.facebook.com'
    }, {
      key: 'Instagram',
      icon: <Instagram fontSize={'large'} sx={{
        color: '#C13584'
      }}/>,
      isVisible: true,
      task_id: null,
      url: 'https://www.instagram.com/'
    }, {
      key: 'Linkedin',
      icon: <LinkedIn fontSize={'large'} sx={{
        color: '#0077B5'
      }}/>,
      isVisible: true,
      task_id: null,
      url: 'https://www.linkedin.com'
    }
  ])

  useEffect(() => {
    dispatch(setIsHistoryHidden(viralTaskId === null && viralTopics.length > 0))
  }, [viralTaskId, viralTopics])

  useEffect(() => {
    setViralTaskId(query.get('viral-task-id'))
  }, [])

  useEffect(() => {
    if (currentUser !== null && id !== undefined) {
      setIsLoading(true)
      fetchReminingCredits()
      fetchTasks()

      void tasks.getTopics(currentUser, null, Number(id)).then((response: Topic[]) => {
        dispatch(setSelectedTopic(response[0]))
      }).catch(() => {
        console.error('Error fetching the current topic')
      })
    }
  }, [currentUser])

  useEffect(() => {
    handleViralTopic()
  }, [topic, viralTaskId])

  useEffect(() => {
    setIsViralTopicListVisible(viralTaskId === null && viralTopics.length > 0 && selectedHistoryItem === null)
  }, [viralTopics, selectedHistoryItem])

  const handleViralTopic = () => {
    if (currentUser === null) {
      return
    }

    if (topic?.name.includes('More Topics') === true) {
      void tasks.getViralTopics(currentUser.id).then((response: ViralTopic[]) => {
        setViralTopics(response.slice(3, 9))

        if (viralTaskId !== null) {
          const viralTopic = response.find((item) => item.id === Number(viralTaskId)) ?? null

          if (viralTopic !== null) {
            let content = viralTopic.content

            if (viralTopic.citations.length > 0) {
              const citations = JSON.parse(viralTopic.citations.replaceAll("'", '"'))
              content = content.replaceAll(/\[(\d+)[\]|]/g, (match, number) => `[[${number}]](${citations[number - 1]})`)
            }

            setViralTopicTitle(viralTopic.title)
            setViralTopicText(content)
          }
        }
      })
    }
  }

  useEffect(() => {
    setIsPersonalizationExpanded(personalization.length > 5 && isPersonalizationFocused)
  }, [personalization, isPersonalizationFocused])

  useEffect(() => {
    if (historyItems.length === 0) {
      return
    }

    if (isFromHistoryUpdate) {
      setIsFromHistoryUpdate(false)
      return
    }

    fetchTasks()
    setIsGenerated(false)
    setIsRegenerateDropdownShown(false)
  }, [historyItems])

  useEffect(() => {
    if (Object.keys(taskGroupList).length > 0 && !isAfterGeneration) {
      getCurrentTaskIndex()
    }

    dispatch(setIsSingleTaskTopic(Object.keys(taskGroupList).length === 1))
  }, [taskGroupList])

  useEffect(() => {
    if (selectedHistoryItem !== null) {
      const activeCluster = Object.values(taskGroupList).filter((entry) => {
        return entry.tasks?.find((task) => task.id === selectedHistoryItem.task.id)
      })

      const index = activeCluster.length > 0 ? Object.values(taskGroupList).indexOf(activeCluster[0]) : 0

      Object.keys(taskGroupList).forEach((key: string) => {
        taskGroupList[key].isActive = false
      })

      if (taskGroupList[Object.keys(taskGroupList)[index]] !== undefined) {
        taskGroupList[Object.keys(taskGroupList)[index]].isActive = true
      }

      getCurrentTaskIndex()
      setCurrentTask(selectedHistoryItem.task)
      setSelectedPlatformIndex(platformList.indexOf(selectedHistoryItem.task.platform))
      setRatingValue(selectedHistoryItem.rating)
      setIsRated(selectedHistoryItem.rating !== null)

      setCitations(selectedHistoryItem.citations)
      setGeneratedText(selectedHistoryItem.response ?? '')

      if (selectedHistoryItem.viral_topic !== null) {
        setViralTopicTitle(selectedHistoryItem.viral_topic.title)
      } else if (viralTaskId !== null) {
        const viralTopic = viralTopics.find((item) => item.id === Number(viralTaskId)) ?? null
        setViralTopicTitle(viralTopic?.title ?? '')
      } else if (viralTopics.length > 0) {
        setViralTopicTitle(currentTask?.task_title ?? '')
      } else {
        setViralTopicTitle(null)
      }
    } else if (viralTaskId === null && viralTopics.length > 0) {
      setViralTopicTitle(currentTask?.task_title ?? '')
    } else {
      setCitations(null)
      setViralTopicTitle(null)
      setGeneratedText('')
    }

    if (selectedHistoryItem === null && !isFromPlatformSelection) {
      setSelectedPlatformIndex(null)
    }

    setSelectedLanguage(selectedHistoryItem?.language ?? 'American English dialect')
    setPersonalization(selectedHistoryItem?.personalization ?? '')
    setIsRegenerateDropdownShown(false)
  }, [selectedHistoryItem])

  useEffect(() => {
    if (selectedLanguage === 'Hebrew') {
      setIsTextFromRight('rtl')
    } else {
      setIsTextFromRight('')
    }
  }, [selectedLanguage])

  const handleLanguageChange = (event: SelectChangeEvent) => {
    setSelectedLanguage(event.target.value)
  }

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setGeneratedText(e.target.value)
  }

  const handlePersonalizationChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPersonalization(e.target.value)
  }

  const handlePersonalizationFocus = () => {
    setIsPersonalizationFocused(true)
  }

  const handlePersonalizationBlur = () => {
    setPersonalization((current) => current.trim())
    setIsPersonalizationFocused(false)
  }

  useEffect(() => {
    if (personalizationRef?.current !== null) {
      const textArea = personalizationRef.current.querySelector('textarea')

      if (textArea !== null) {
        textArea.scrollTop = textArea.scrollHeight
      }
    }
  }, [isPersonalizationExpanded])

  const fetchTasks = () => {
    if (id === undefined || currentUser === null) {
      return
    }

    void tasks.getGenerationTasksByTopicId(parseInt(id), currentUser).then((result) => {
      const taskGroup: TaskGroup = {}

      result.forEach((task: Task) => {
        if (!(task.task_title in taskGroup)) {
          taskGroup[task.task_title] = {}
        }
      })

      const historyTaskIds = historyItems.map((historyTask) => historyTask.task.id)

      Object.keys(taskGroup).map((taskGroupKey: string) => {
        const tasks = result.filter((task: Task) => {
          return task.task_title === taskGroupKey
        })

        taskGroup[taskGroupKey] = {
          tasks,
          isActive: false,
          isVisible: Object.keys(taskGroup).length === 1 || tasks.filter((task: Task) => historyTaskIds.includes(task.id) && !task.is_free_writing && !task.is_rewrite).length === 0
        }

        return taskGroup
      })

      setTaskList(result)
      setTaskGroupList(taskGroup)
      setIsLoading(false)
    }).finally(() => {
      if (!currentUser.has_viewed_content_generator) {
        void users.updateUser(currentUser.id, {
          has_viewed_content_generator: true
        })

        setTimeout(() => {
          dispatch(setAutoViewGuide(true))
        }, 500)
      }
    })
  }

  const fetchReminingCredits = () => {
    if (currentUser === null) {
      return
    }

    void users.getRemainingCredits().then((response) => {
      dispatch(setCredits(response.credits))
    })
  }

  const getCurrentTaskIndex = () => {
    const isFromHistory = selectedHistoryItem !== null
    const activeTask = Object.values(taskGroupList).filter((entry) => {
      return (entry.isActive === true) && (isFromHistory || (!isFromHistory && entry.isVisible))
    })

    const index = activeTask.length > 0 ? Object.values(taskGroupList).filter((item) => (item.isVisible ?? false) || isFromHistory).indexOf(activeTask[0]) : 0

    if (activeTask.length === 0 && taskList.length > 0) {
      taskList[0].isActive = true
      activeTask.push(taskList[0])
    }

    const tasks = Object.values(taskGroupList).filter((item) => (item.isVisible ?? false) || isFromHistory)[index]?.tasks

    if (tasks !== undefined) {
      if (tasks.length > 1) {
        setSelectedPlatformIndex(null)
      } else {
        setSelectedPlatformIndex(platformList.indexOf(tasks[0].platform))
      }
    }

    if (!isFromHistory) {
      setCurrentTask(tasks?.[0] ?? null)
    }

    setClusterIndex(index)
    setVisiblePlatforms(index, isFromHistory)
  }

  const handleNextTask = () => {
    let index = 0
    if (clusterIndex < Object.values(taskGroupList).filter((item) => item.isVisible).length - 1) {
      index = clusterIndex + 1
    }

    moveToTask(index)
  }

  const handlePrevTask = () => {
    let index = Object.values(taskGroupList).filter((item) => item.isVisible).length - 1
    if (clusterIndex > 0) {
      index = clusterIndex - 1
    }

    moveToTask(index)
  }

  const moveToTask = (index: number) => {
    setIsFromPlatformSelection(true)
    setIsAfterGeneration(false)
    setHasPlatformSelectionError(false)

    if (selectedHistoryItem !== null) {
      index = 0
    }

    Object.keys(taskGroupList).forEach((key: string) => {
      taskGroupList[key].isActive = false
    })

    taskGroupList[Object.keys(taskGroupList).filter((key) => taskGroupList[key].isVisible)[index]].isActive = true
    dispatch(setSelectedContentHistory(null))
    getCurrentTaskIndex()
  }

  const setVisiblePlatforms = (clusterIndex: number, isFromHistory: boolean) => {
    const tasks = taskGroupList[Object.keys(taskGroupList).filter((key) => (taskGroupList[key].isVisible ?? false) || isFromHistory)[clusterIndex]]
      ?.tasks?.reduce((acc: Record<string, number>, task: Task) => {
        acc[task.platform] = task.id
        return acc
      }, {}) ?? {}

    if (tasks === undefined) {
      return
    }

    const localPlatforms = platforms.map((platform: Platform) => {
      platform.isVisible = tasks[platform.key] !== undefined
      platform.task_id = tasks[platform.key] ?? null
      return platform
    })

    setPlatforms([...localPlatforms])
  }

  const handleCopy = () => {
    if (generatedText === '') {
      return
    }

    void navigator.clipboard.writeText(generatedText).then(() => {
      toast.success('Text copied to clipboard')

      if (selectedPlatformIndex !== null) {
        window.open(platforms[selectedPlatformIndex].url, '_blank', 'noopener,noreferrer')
      }
    }).catch(() => {
      toast.error('Something went wrong copying the content, please do it manually.')
    })
  }

  const handlePlatformSelection = (index: number, platform: Platform) => {
    const task = taskList.find((task: Task) => task.id === platform.task_id) ?? null
    setCurrentTask(task)
    setSelectedPlatformIndex(index)
    setHasPlatformSelectionError(false)

    if (task !== null || Object.keys(taskGroupList).length === 1) {
      setIsFromPlatformSelection(true)
      dispatch(setSelectedContentHistory(null))
      return
    }

    const historyItem = historyItems.find((item: ContentHistory) => {
      return item.task.id === platform.task_id && item.task.platform === platform.key
    }) ?? null

    if (historyItem !== null) {
      dispatch(setSelectedContentHistory(historyItem))
    } else {
      setIsFromPlatformSelection(true)
      dispatch(setSelectedContentHistory(null))
    }
  }

  const handleGenerate = (regenerationNote: string | null) => {
    if (currentUser === null || currentTask === null || id === undefined) {
      navigate('/sign-in')
      return
    }

    if (remainingCredits === 0) {
      setIsModalOpen(true)
      return
    }

    if (selectedPlatformIndex === null) {
      setHasPlatformSelectionError(true)
      window.scrollTo(0, 0)
      return
    }

    if (currentTask.is_rewrite && personalization === '') {
      setHasPersonalizationError(true)
      window.scrollTo(0, 0)
      return
    }

    setHasPersonalizationError(false)
    void users.getGenerationHistoryList(null, currentUser.id).then(() => {
      setIsGenerated(true)
      setIsLoading(true)

      let regenerationType = null
      if (currentTask.is_rewrite) {
        regenerationType = RegenerationType.REWRITE
      } else if (selectedHistoryItem !== null) {
        regenerationType = RegenerationType.REGENERATE
      }

      void tasks.generate(
        currentUser,
        currentTask.id,
        personalization,
        selectedLanguage,
        currentTask.is_free_writing,
        regenerationType,
        selectedHistoryItem?.id ?? null,
        regenerationNote,
        viralTaskId !== null ? (selectedHistoryItem?.viral_topic?.id ?? Number(viralTaskId)) : null
      ).then(() => {
        void users.getGenerationHistoryList(parseInt(id), currentUser.id).then((response) => {
          setIsRegenerateDropdownShown(false)
          setIsAfterGeneration(true)
          dispatch(setContentHistory(response))
          dispatch(setSelectedContentHistory(response[0]))
        })
      }).catch(() => {
        toast.error('Something went wrong. Please try again later!')
      }).finally(() => {
        void users.getRemainingCredits().then((response) => {
          dispatch(setCredits(response.credits))
        }).finally(() => {
          setIsLoading(false)
        })
      })
    })
  }

  const handleAddToCalendar = () => {
    if (currentUser === null || selectedHistoryItem === null) {
      return
    }

    setIsCalendarLoading(true)
    void users.addToCalendar(selectedHistoryItem.id)
      .then(() => {
        toast.success('An email has been sent to your account')
      })
      .catch(() => {
        toast.success('Something went wrong sending you the email, please try again later!')
      })
      .finally(() => {
        setIsCalendarLoading(false)
      })
  }

  const renderErrorLabel = (label: string, className: string = 'text-purple-600') => {
    return (
      <span className={`${className} pb-6 inline-block text-sm`}>{label}</span>
    )
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const steps = [
    {
      target: '.guide-title',
      content: '',
      disableBeacon: true
    },
    {
      target: '.task-body',
      content: 'Read what this post is all about! 🔎'
    },
    {
      target: '.next-button',
      content: 'You can browse more post ideas here! 💡'
    },
    {
      target: '.platform',
      content: 'Click on the platform you’ll be posting 🤘🏻'
    },
    {
      target: '.language',
      content: 'Choose your preferred content language per post. (American English is the default) 💬'
    },
    {
      target: '.personalization',
      content: 'Further personalize your suggestion by simply answering what is asked here. For better results, write your answer in the same language you chose (optional). Keep it short and precise!  ✍️'
    },
    {
      target: '.generate',
      content: 'Click here to generate your post ✨'
    },
    {
      target: '.suggestion',
      content: 'Your suggestion will appear here. Make sure to edit it to your needs and delete all irrelevant instructions! ⛔',
      placement: 'top' as Placement,
      styles: isMobile
        ? {
            tooltip: {
              top: 0,
              transform: 'translateY(-50%)',
              zIndex: 100
            }
          }
        : {}
    },
    {
      target: '.generate',
      content: 'You can always get another suggestion by clicking ‘Regenerate’ 🔃'
    },
    {
      target: '.copy-button',
      content: 'Here you can copy the final version and be redirected to your chosen platform. We will never automatically post for you!'
    },
    {
      target: '.calendar',
      content: 'Click to get a link to block a preparation time in your calendar!'
    },
    {
      target: '.history',
      content: 'All your generations will be saved here. You can access and regenerate them whenever you like 🪶'
    }
  ]

  useEffect(() => {
    setRunGuide(isGuideManuallyRunning)
  }, [isGuideManuallyRunning])

  useEffect(() => {
    setRunGuide(autoViewGuide)

    if (!autoViewGuide && currentUser !== null) {
      const user = {
        ...currentUser,
        has_viewed_content_generator: true
      }

      dispatch(setCurrentUser(user))
    }
  }, [autoViewGuide])

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
      dispatch(setIsGuideRunning(false))
      dispatch(setAutoViewGuide(false))

      if (isFirstTime) {
        navigate('/content-generator')
      }
    }
  }

  const handleHistoryUpdate = () => {
    if (selectedHistoryItem === null) {
      return
    }

    if (selectedHistoryItem.response === generatedText) {
      return
    }

    void tasks.updateHistoryItem(selectedHistoryItem.id, {
      response: generatedText
    }).then(() => {
      setIsFromHistoryUpdate(true)

      const updatedItem = {
        ...selectedHistoryItem,
        response: generatedText
      }

      const updatedItems = historyItems.map((item) => {
        if (item.id === selectedHistoryItem.id) {
          return {
            ...item,
            response: generatedText
          }
        }

        return item
      })

      dispatch(setContentHistory(updatedItems))
      dispatch(setSelectedContentHistory(updatedItem))
    })
  }

  const handleRatingUpdate = (selectedHistoryItem: ContentHistory) => {
    setIsRated(true)
    void tasks.updateHistoryItem(selectedHistoryItem.id, { rating: ratingValue }).then(() => {
      const updatedItem = {
        ...selectedHistoryItem,
        rating: ratingValue
      }

      const updatedItems = historyItems.map((item) => {
        if (item.id === selectedHistoryItem.id) {
          return {
            ...item,
            rating: ratingValue
          }
        }

        return item
      })

      dispatch(setContentHistory(updatedItems))
      dispatch(setSelectedContentHistory(updatedItem))
    })
  }

  const handleTopicSelection = (viralTopic: ViralTopic) => {
    setViralTaskId(viralTopic.id + '')
  }

  return (
    <div className={className}>

      {runGuide &&
          <div className='absolute top-2 left-[50%] -ml-[125px] bg-white -md:ml-[200px] w-[250px] md:w-[400px] text-center guide-title'>
              <h1 className='text-xl md:text-2xl'>Take Our Brand Generator Tour!</h1>
              <p className='text-sm md:text-base'>To get the most out of myBranda ®</p>
          </div>
      }

      <Joyride
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        hideCloseButton={true}
        showSkipButton={true}
        key={new Date().getTime()}
        continuous={true}
        steps={steps}
        run={runGuide}
        callback={handleJoyrideCallback}
        locale={{
          last: 'Start Posting'
        }}
      />
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isLoading}
        className='flex flex-col justify-center'
      >
        <CircularProgress color="inherit"/>
        {isGenerated && <p className='mt-4 text-xl'><AutoAwesome className='mr-2' /> Perfection takes time <AutoAwesome className='ml-2' /></p>}
      </Backdrop>

      <Navigator
        className={`flex justify-between items-center mb-6 ${Object.keys(taskGroupList).length === 1 ? 'hidden' : ''}`}
        handleNext={handleNextTask}
        handlePrevious={handlePrevTask}
        currentCount={selectedHistoryItem !== null ? 0 : (clusterIndex + 1)}
        totalCount={Object.values(taskGroupList).filter((item) => item.isVisible).length}/>

      <div className='flex flex-col md:flex-row items-start mb-4'>
        <div className='task-body md:mr-2'>
          <p className='text-3xl w-full mb-2'>{
              viralTopics.length > 0 && viralTopicTitle !== null ? viralTopicTitle : (currentTask?.task_title ?? 'No More Posts Left')
          }</p>
          <p>{viralTaskId !== null ? viralTopicSubtitle : currentTask?.task_body}</p>
        </div>
        <div className='flex flex-col justify-start md:justify-end w-full md:w-1/4 md:text-right'>
        <TaskPlatformList
            className={`platform flex justify-start md:justify-end gap-x-2 mt-3 mb-1 md:mt-0 ${Object.keys(taskGroupList).length === 0 || isViralTopicListVisible ? 'hidden' : ''}`}
            platforms={platforms}
            handlePlatformSelection={handlePlatformSelection}
            selectedPlatformIndex={selectedPlatformIndex}/>
          {hasPlatformSelectionError && renderErrorLabel('Please select a platform', 'text-red-600')}
        </div>
      </div>
      {currentTask === null && <p>Please select one of the items in the history or go back to generate new posts</p>}
      <div className={currentTask === null ? 'hidden' : ''}>
        {!isViralTopicListVisible && <div
          className={`transition-all flex flex-col ${isPersonalizationExpanded ? 'md:flex-col-reverse items-end md:gap-y-6' : 'md:flex-row md:gap-y-0'} mt-10 gap-y-6 md:gap-x-5`}>
          <div className={`${isPersonalizationExpanded ? 'md:w-full' : 'md:w-3/5'} w-full personalization`}>
            <TextField
                ref={personalizationRef}
              multiline={true}
              rows={isPersonalizationExpanded ? 4 : 1}
              onFocus={handlePersonalizationFocus}
              onBlur={handlePersonalizationBlur}
              sx={hasPersonalizationError
                ? {
                    '& .MuiOutlinedInput-root': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#dc2626',
                        borderWidth: '2px'
                      }
                    },
                    '& .MuiInputLabel-outlined': {
                      color: '#dc2626'
                    }
                  }
                : {}}
              className={`w-full transition-all ${isPersonalizationExpanded ? 'h-36' : 'h-12'} ${isTextFromRight}`}
              label={'Personalize'}
              disabled={isLoading}
              placeholder={currentTask?.personalization}
              onChange={handlePersonalizationChange}
              value={currentTask?.subject_for_ai === true ? personalization : "This one's on us - let us suggest it for you!"}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: currentTask?.subject_for_ai === false
              }}
            />
            {hasPersonalizationError && renderErrorLabel('Please add the post you want to transform!', 'text-red-600 !pb-0 !pt-4')}
          </div>
          <div className='w-full md:w-2/5'>
            <FormControl className='w-full language'>
              <InputLabel id="simple-select-label">
                Choose Language
              </InputLabel>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                label="Choose Language"
                onChange={handleLanguageChange}
                value={selectedLanguage}
              >
                {LANGUAGES.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    <img
                      alt={`${item.value}`}
                      src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${item.img_value}.svg`}
                      className={'inline mr-2 h-4 rounded-sm'}
                    />
                    {item.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>}

        <div className='mt-4'>
          {isViralTopicListVisible && (
              <div className='flex flex-col flex-wrap md:flex-row gap-2 p-4 mt-8 rounded-3xl shadow-xl'>
                {viralTopics.map((item: ViralTopic) => (
                    <div
                        onClick={() => {
                          handleTopicSelection(item)
                        }}
                        className='w-full md:w-[32%] cursor-pointer text-sm h-16 p-3 text-center rounded-lg bg-[#f4ebc2] hover:opacity-90 text-black flex justify-center items-center' key={item.id}>
                      {item.title}
                    </div>
                ))}
              </div>
          )}

          {(generatedText !== '' || viralTopics.length === 0) && <TextareaAutosize
            className={`suggestion rounded-lg bg-white border border-gray-300 w-full p-4 pb-[66px] text-base ${isTextFromRight}`}
            value={generatedText}
            ref={inputRef}
            disabled={isLoading}
            onBlur={handleHistoryUpdate}
            onChange={handleTextareaChange}
            placeholder='Your suggestion will appear here'
            minRows={8}
          />}

          {generatedText === '' && viralTopicText !== null && (
              <div className='p-4 bg-white shadow-lg rounded-3xl mb-2'>
                <ReactMarkdown className={'react-md'}>{viralTopicText}</ReactMarkdown>
              </div>
          )}
        </div>

        {(selectedHistoryItem !== null || runGuide) &&
            <Tooltip
                title='Want to post this content later? Block preparation time to make sure this post is published 💪'>
                <button
                    disabled={isCalendarLoading}
                    onClick={handleAddToCalendar}
                    className='calendar rounded-lg py-1 px-4 border-2 hover:bg-[#f1e0ff] text-[#8847BF] border-[#8847BF] flex flex-row justify-center items-center -translate-y-14 ml-3'>
                  {isCalendarLoading ? <CircularProgress color={'inherit'} size={20}/> : <Event fontSize={'small'}/>}
                    <span className='ml-2 inline-block transition-all font-bold text-xs'>
                      Add this post to your calendar
                    </span>
                </button>
            </Tooltip>
        }

        {citations !== null && citations.length > 0 && (
            <div className='p-4 bg-white shadow-lg rounded-3xl mb-12 text-sm'>
              <p className='font-medium'>References</p>
                {citations.map((item, index) => (
                  <div key={item} className='my-2'>
                    [{index + 1}] <a target='_blank' className='text-blue-800 hover:underline' href={item} rel="noreferrer">{item}</a>
                  </div>
                ))}
            </div>
        )}

        {!(viralTaskId === null && viralTopics.length > 0) &&
        <ButtonGroup
          handleGenerate={() => {
            if (selectedHistoryItem === null) {
              handleGenerate(null)
            } else {
              setIsRegenerateDropdownShown(!isRegenerateDropdownShown)
            }
          }}
          isRegenerate={selectedHistoryItem !== null}
          isTransform={viralTaskId !== null}
          className={`flex flex-col md:flex-row gap-x-4 justify-end ${selectedHistoryItem !== null || runGuide ? '-mt-9' : ''}`}
          isGenerateButtonDisabled={isLoading}
          tooltipText='Please select a platform'
          isTextGenerated={generatedText !== ''}
          isCopyButtonHidden={isRegenerateDropdownShown}
          handleCopy={handleCopy}/>
        }
        <RegenerateDropdown isRTL={isTextFromRight === 'rtl'} isOpen={isRegenerateDropdownShown} onButtonClick={handleGenerate} />
      </div>

      {selectedHistoryItem !== null && <div className='flex flex-col items-center justify-center py-4'>
        <p className='mb-1.5 text-xs'>{isRated ? 'Thanks for the feedback' : 'How was this suggestion?'}</p>
        <Rating
            size={'large'}
            name="star-rating"
            value={ratingValue}
            onChange={(event, newValue) => {
              setRatingValue(newValue)
            }}
            readOnly={isRated}
        />
        {ratingValue !== null && !isRated && <button
            onClick={() => {
              handleRatingUpdate(selectedHistoryItem)
            }}
            className={'text-xs border border-[#8847BF] text-[#8847BF] rounded-md px-3 py-0.5 mt-2 text-center relative'}>
          Send
        </button>}
      </div>}

      <EndTrialModal
          isOpen={isModalOpen}
          onCloseModal={handleCloseModal}
      />
    </div>
  )
}
