import React from 'react'
import { PopoverTooltip } from '../../molecules/PopOver/PopoverTooltip'

interface Props {
  className: string
  text: string
  number?: number | null
  isActive: boolean
  id: number
  handleCardClick: (id: number) => void
  tooltip?: string
}

export default function Card ({ className, text, number, isActive, id, handleCardClick, tooltip }: Props) {
  return (
    <div onClick={() => {
      handleCardClick(id)
    }} className={`${isActive ? className + ' active' : className} relative`}>
      {isActive && number !== 0 && <div className="number">{number}</div>}
      <p>{text}</p> {tooltip !== undefined &&
          <PopoverTooltip iconSize={'inherit'} tooltip={tooltip} className='absolute top-[50%] -translate-y-1/2 right-3 inline-block ml-2 opacity-50' />
    }
    </div>
  )
}
