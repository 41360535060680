/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Input from '../../atoms/Input/Input'
import { toast } from 'react-toastify'
import { preSignUpUser } from '../../../app/services/Api'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import LoadingButton from '@mui/lab/LoadingButton'
import CountrySelector from '../../atoms/CountryDropdown/countrySelector'
import { COUNTRIES } from '../../atoms/CountryDropdown/countries'
import axios from 'axios'
import {PAGE, setCountry, setFirstName, setLastName, setUserPreSignupId} from '../../../app/quiz/quizSlice'
import { type SelectMenuOption } from '../../atoms/CountryDropdown/types'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizEmailPageForm ({ className, handleShowPage }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  const [isRequestProccessed, setIsRequestProccessed] = useState(false)
  const [loading, setLoading] = React.useState(false)
  const name = useAppSelector(state => state.quizReducer.first_name)
  const last_name = useAppSelector(state => state.quizReducer.last_name)
  const country = useAppSelector(state => state.quizReducer.country)
  const email = useAppSelector(state => state.quizReducer.email)
  const isButtonDisabled = name === '' || last_name == '' || email === '' 
  const dispatch = useAppDispatch()

  const query = new URLSearchParams(location.search)

  if (query.get('first_name') && !localStorage.getItem('pre_sign_up_user')) {
    const user = JSON.stringify({
      email: query.get('email') ?? '',
      name: query.get('first_name') ?? '',
      last_name: query.get('last_name') ?? '',
      country: query.get('country') ?? '',
    })

    setUserPreSignupId(Number(query.get('id')) ?? 0)
    localStorage.setItem('pre_sign_up_user', user.replaceAll(' ', '+'))

    handleShowPage('verification_code')
  }

  useEffect(() => {
    const fetchUserCountry = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/')
        dispatch(setCountry(response.data.country))
      } catch (error) {
        console.log('Error fetching user country:', error)
      }
    }
    // Call the function to fetch the user's country when the component mounts
    fetchUserCountry()
  }, [dispatch])

  const handleEmailValidation = async () => {
    const user = {email, name, last_name, country}

    if (query.get('first_name')) {
      user['email'] = query.get('email') ?? ''
      user['name'] = query.get('first_name') ?? ''
      user['last_name'] = query.get('last_name') ?? ''
      user['country'] = query.get('country') ?? ''

      setUserPreSignupId(Number(query.get('id')) ?? 0)
      handleShowPage('verification_code')
    }

    const stringifiedUser: string = JSON.stringify(user)
    if (!localStorage.getItem('pre_sign_up_user') || (localStorage.getItem('pre_sign_up_user') !== stringifiedUser && email !== '')) {
      localStorage.setItem('pre_sign_up_user', stringifiedUser)
    }

    const stringifiedUserFromStorage = JSON.parse(localStorage.getItem('pre_sign_up_user') ?? '')

     await preSignUpUser.sendEmailVerification(
      stringifiedUserFromStorage.email, 
      stringifiedUserFromStorage.name, 
      stringifiedUserFromStorage.last_name, 
      stringifiedUserFromStorage.country
    ).then((response) => {
       setLoading(false)
       setIsRequestProccessed(true)

      if (response.status === 202) {
        handleShowPage(response.data.page)
      } else {
        handleShowPage(PAGE.VERIFICATION_CODE)
      }
    }).catch((error) => {
      console.log(error)
      setIsRequestProccessed(false)
      if (error.response.data !== null) {
        if (error.response.data.email) {
          toast.warning('Invalid email address. Please try again!')
        } else {
          toast.error(error.response.data.message)
        }
      } else {
        toast.error('Failed to send email verification. Please try again!')
      }
      setLoading(false)
    })
  }

  const handleCountryChange = (value: SelectMenuOption['value']) => {
    dispatch(setCountry(value))
  }

  const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setFirstName(e.target.value))
  }

  const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLastName(e.target.value))
  }

  const handleSubmit = () => {
    setLoading(true)
    void handleEmailValidation()
  }

  const loadingButtonText = (isLoading: boolean, isRequestProccessed: boolean) => {
    if (isLoading || isRequestProccessed) {
      return '';
    }

    return 'Sign-up'
  }

  return (
    <div className={className}>
      <Input onSubmit={handleSubmit} email={false} onChange={handleFirstNameChange} placeholder='First Name' type='text' className='bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/4 md:w-1/6 h-14 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />
      <Input onSubmit={handleSubmit} email={false} onChange={handleLastNameChange} placeholder='Last Name' type='text' className='bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/4 md:w-1/6 h-14 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />
      <Input onSubmit={handleSubmit} email={true} placeholder='Email' type='email' className='bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-3/4 md:w-1/6 h-14 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />
      <CountrySelector
        id="country-selector"
        open={isOpen}
        onToggle={() => { setIsOpen(!isOpen) }}
        onChange={handleCountryChange}
        selectedValue={COUNTRIES.find((option) => option.value === country)}
      />
      <LoadingButton
        onClick={handleSubmit}
        loading={loading}
        loadingIndicator={<CircularProgress color="inherit" size={28} />}
        disabled={isButtonDisabled}
        color='inherit'
        className='quiz_form_button quiz_form_submition !normal-case !text-white !font-medium !rounded-lg px-5 py-2.5 text-center mr-2 h-14'
      >{loadingButtonText(loading, isRequestProccessed)}</LoadingButton>
    </div>
  )
}
